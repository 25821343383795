<template>
  <div class="">
    <div class="block">
      <el-carousel height="240px">
        <el-carousel-item v-for="(item, index) in img_arr" :key="index">
          <div class="item">
            <img :src="item.current" style="width: 100%;height: 240px" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="block1">
        <div class="h5">{{ $t('message.产品中心') }}</div>
        <scrollView style="background: transparent;">
          <div class="monitor-list">
            <!-- 中间列表 -->
            <div id="list-box" class="list-box">
              <div id="list" class="list">
                 <div class="list-item">
                  <img src="@/assets/wugeshoumaiji.png" />
                  <div class="info">
                    {{ $t('message.5格售卖机') }}
                  </div>
<!--                    <div class="readMore row-s">Read more <i class="el-icon el-icon-right" style="font-size: 24px;margin-left: 5px" /></div>-->
                </div>
                <div class="list-item">
                  <img src="@/assets/wugeshoujmaiji2.png" />
                  <div class="info">
                    {{ $t('message.5格售卖机') }}
                  </div>
<!--                  <div class="readMore row-s">Read more <i class="el-icon el-icon-right" style="font-size: 24px;margin-left: 5px" /></div>-->
                </div>
                <div class="list-item">
                  <img src="@/assets/liugeshoumaiji.png" />
                  <div class="info">
                    {{ $t('message.6格售卖机') }}
                  </div>
<!--                  <div class="readMore row-s">Read more <i class="el-icon el-icon-right" style="font-size: 24px;margin-left: 5px" /></div>-->
                </div>
                <div class="list-item">
                  <img src="@/assets/qigeshoumaiji.png" />
                  <div class="info">
                    {{ $t('message.7格售卖机') }}
                  </div>
<!--                  <div class="readMore row-s">Read more <i class="el-icon el-icon-right" style="font-size: 24px;margin-left: 5px" /></div>-->
                </div>
              </div>
            </div>
          </div>
        </scrollView>
      </div>
    </div>
    <div class="info2">
      <div class="h5">{{ $t('message.产品特点') }}</div>
      <div class="introduction">{{ $t('message.小型酒店售货机是一种基于互联网和智能技术的自动售货机') }}</div>
      <div class="txt">
        {{ $t('message.可帮助酒店提升旅客体验') }}
      </div>
    </div>
    <div class="home1 row-b">
        <div class="box1 row-c row-d">
          <img src="@/assets/icon1.png"></img>
          <div class="txt">{{ $t('message.立式橱窗') }}</div>
        </div>
      <div class="box1 row-c row-d">
        <img src="@/assets/icon2.png"></img>
        <div class="txt">{{ $t('message.电池/电源供电') }}</div>
      </div>
      <div class="box1 row-c row-d">
        <img src="@/assets/icon3.png"></img>
        <div class="txt">{{ $t('message.蓝牙连接') }}</div>
      </div>
      <div class="box1 row-c row-d">
        <img src="@/assets/icon4.png"></img>
        <div class="txt">{{ $t('message.多种配色可选') }}</div>
      </div>
      <div class="box1 row-c row-d">
        <img src="@/assets/icon5.png"></img>
        <div class="txt">{{ $t('message.缺货提醒') }}</div>
      </div>
      <div class="box1 row-c row-d">
          <img src="@/assets/icon6.png"></img>
          <div class="txt" style="font-size: 14px">{{ $t('message.手机扫码支付') }}</div>
      </div>
    </div>
    <div class="home2" :style="{ backgroundImage: `url(${a2})`}">
        <div class="h5">{{ $t('message.我们的优势') }}</div>
        <div class="block3">
              <div class="list row-b">
                <div class="li row-c row-d">
                  <img src="@/assets/advantage1.png" />
                  <div class="info">
                    <div class="label">{{ $t('message.服务周到') }}</div>
                    <div class="txt">{{ $t('message.一站式服务') }}</div>
                  </div>
                </div>
                <div class="li row-c row-d">
                  <img src="@/assets/advantage2.png" />
                  <div class="info">
                    <div class="label">{{ $t('message.性能优秀') }}</div>
                    <div class="txt">{{ $t('message.设备性能稳定便捷') }}</div>
                  </div>
                </div>
                <div class="li row-c row-d">
                  <img src="@/assets/advantage3.png" />
                  <div class="info">
                    <div class="label">{{ $t('message.价格更低') }}</div>
                    <div class="txt">{{ $t('message.量大价格低') }}</div>
                  </div>
                </div>
                <div class="li row-c row-d">
                  <img src="@/assets/advantage4.png" />
                  <div class="info">
                    <div class="label">{{ $t('message.互利分成') }}</div>
                    <div class="txt">{{ $t('message.酒店合作联营') }}</div>
                  </div>
                </div>

              </div>
        </div>
    </div>

    <div class="home3" :style="{ backgroundImage: `url(${a3})`}">
      <div class="h5">{{ $t('message.合作流程') }}</div>
      <div class="block4">
        <div class="list row-b">
          <div class="li row-s">
            <img src="@/assets/cooperation1.png" />
            {{ $t('message.在线咨询') }}
          </div>
          <div class="li row-s">
            <img src="@/assets/cooperation2.png" />
            {{ $t('message.了解需求') }}
          </div>
          <div class="li row-s">
            <img src="@/assets/cooperation3.png" />
            {{ $t('message.制定方案') }}
          </div>
          <div class="li row-s">
            <img src="@/assets/cooperation4.png" />
            {{ $t('message.签订合同') }}
          </div>
          <div class="li row-s">
            <img src="@/assets/cooperation5.png" />
            {{ $t('message.投入使用') }}
          </div>
          <div class="li row-s">
            <img src="@/assets/cooperation6.png" />
            {{ $t('message.售后维护') }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ScrollView from '@/components/scrollView'
export default {
  name: "Tab",
  data() {
    return {
      monitorList: [
        {img:'',title:this.$t('message.5格售卖机')},
        {img:'',title:this.$t('message.6格售卖机')},
        {img:'',title:this.$t('message.7格售卖机')},
        {img:'',title:this.$t('message.8格售卖机')},
      ],
      img_arr:[
        {
          img: {
            En: require("../assets/MobileImage4_en.png"),
            zh: require("../assets/MobileImage4.png"),
          },
          current: '', // will be updated in created() method
        },
      ],
      a1:require("@/assets/vendingMachineBg.png"),
      a2:require("@/assets/vendingMachineHome2.png"),
      a3:require("@/assets/vendingMachineHome3.png"),
    };
  },
  props:{
    lang: {
      type:String,
      default:'zh'
    }
  },
  watch:{
    lang(newValue){
      for (const item of this.img_arr) {
        item.current = item.img[newValue]
      }
    }
  },
  components: {
    /* 注册子组件 */
    ScrollView
  },
  created() {
    for (const item of this.img_arr) {
      item.current = item.img[this.lang]
    }
  },
  mounted() {
    this.initMonitorList()
  },
  methods:{
    initMonitorList() {
      for (let i = 1; i < 6; i++) {
        this.monitorList.push({
          id: i,
          name: `item${i + 1}`,
          status: 0
        })
      }
    },
    // 左滑动逻辑
    scrollLeft() {
      const allLength = this.monitorList.length * 441
      const boxLength = document.getElementById('list-box').clientWidth
      if (allLength < boxLength) return
      const listEl = document.getElementById('list')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      if (leftMove + boxLength - 1323 < boxLength) {
        // 到最开始的时候
        listEl.style.left = '0px'
      } else {
        listEl.style.left = '-' + (leftMove - 1323) + 'px'
      }
    },
    // 右滑动逻辑
    scrollRight() {
      const allLength = this.monitorList.length * 441
      const boxLength = document.getElementById('list-box').clientWidth
      if (allLength < boxLength) return
      const listEl = document.getElementById('list')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      if (leftMove + boxLength + 1323 > allLength) {
        listEl.style.left = '-' + (allLength - boxLength) + 'px'
      } else {
        listEl.style.left = '-' + (leftMove + 1323) + 'px'
      }
    }

  },
};
</script>

<style scoped lang="less">
.content{
  background: #F6FAFF;
  padding-bottom: 74px;
  .block1{
    //margin: 0 170px;
    .h5{
      padding: 52px 0 33px 0;
      font-weight: 600;
      font-size: 31px;
      color: #202020;
      text-align: center;
    }
    .monitor-list {
      .list-box {
        //width: calc(100vw - 100px);
        //overflow: hidden;
        padding: 0 20px;
        .list {
          //width: calc(100vw - 100px);
          display: flex;
          //transform: all 2s;
          .list-item {
            //width: 313px;
            height: 406px;
            background: #FFFFFF;
            box-shadow: 0px 0 13px 7px rgba(0,0,0,0.05);
            cursor: pointer;
            margin-right: 17px;
            flex-shrink: 0;
            padding: 30px 24px 0;
            border-radius: 10px;
            img{
              width: 266px;
              height: 266px;
              background: #D9D9D9;
              border-radius: 10px 10px 10px 10px;
              margin-bottom: 24px;
            }
            .info{

              font-weight: 600;
              font-size: 19px;
              color: #202020;
              padding-bottom: 20px;
              height: 64px;
              width: 266px;
              white-space: pre-wrap;
              //border-bottom: 1px solid #D6D6D6;
            }
            .readMore{
              margin-top: 26px;
              font-weight: 600;
              font-size: 16px;
              color: #9A40F4;
            }
          }
          position: relative;
          left: 0;
          transition: left 1s;
        }
      }
    }
  }
}
.info2{
  padding: 20px;
  .h5{
    font-weight: 600;
    font-size: 31px;
    color: #202020;
    margin-bottom: 21px;
  }
  .introduction{
    font-weight: 600;
    font-size: 21px;
    color: #202020;
    //width: 480px;
    margin-bottom:26px ;
    line-height:30px;
    white-space: pre-wrap;
  }
  .txt{
    font-weight: 400;
    font-size: 17px;
    color: #202020;
    //width: 468px;
    line-height:30px;
  }
}
.home1{
  background-size: 100%;
  background-repeat: no-repeat;
  //height: 1125px;
  //position: relative;
  flex-wrap: wrap;
  .box1{
    width: 49.8%;
    border-right: 1px solid #939393;
    border-bottom: 1px solid #939393;
    height: 187px;
    padding: 30px 0;
    img{
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }
    .txt{
      font-weight: 600;
      font-size: 13px;
      color: #202020;
      //line-height: 40px;
      //width: px;
      text-align: center;
    }
  }
  .box1:nth-child(2n){
    border-right: none;
  }

  .box2{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 128px;
    height:128px;
    background: #FFFFFF;
    border: 2px solid #9A40F4;
    border-radius: 100%;
    z-index: 1;
    .order{
      font-weight: 600;
      font-size: 13px;
      color: #202020;
      margin-bottom: 5px;
    }
    .txt{
      font-weight: 600;
      font-size: 13px;
      color: #202020;
      //line-height: 40px;
      width: 87px;
      text-align: center;
    }
  }

  .box3{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 120px;
    height: 120px;
    background: #FFFFFF;
    border: 2px solid #9A40F4;
    border-radius: 100%;
    z-index: 1;
    .order{
      font-weight: 600;
      font-size: 13px;
      color: #202020;
      margin-bottom: 5px;
    }
    .txt{
      font-weight: 600;
      font-size: 13px;
      color: #202020;
      //line-height: 40px;
      width: 82px;
      text-align: center;
    }
  }

  .box4{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 127px;
    height: 127px;
    background: #FFFFFF;
    border: 2px solid #9A40F4;
    border-radius: 100%;
    z-index: 1;
    .order{
      font-weight: 600;
      font-size: 13px;
      color: #202020;
      margin-bottom: 5px;
    }
    .txt{
      font-weight: 600;
      font-size: 13px;
      color: #202020;
      //line-height: 40px;
      width: 82px;
      text-align: center;
    }
  }

  .box5{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 84px;
    height: 84px;
    background: #FFFFFF;
    border: 2px solid #9A40F4;
    border-radius: 100%;
    z-index: 1;
    .order{
      font-weight: 600;
      font-size: 13px;
      color: #202020;
      margin-bottom: 5px;
    }
    .txt{
      font-weight: 600;
      font-size: 13px;
      color: #202020;
      //line-height: 40px;
      width: 65px;
      text-align: center;
    }
  }

  .box6{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 222px;
    height: 222px;
    background: #C4C4C4;
    border-radius: 100%;
    z-index: 0;
    .box7{
      width: 186px;
      height: 186px;
      background: #9A40F4;
      border: 2px solid #fff;
      border-radius: 100%;
    }
    .order{
      font-weight: 600;
      font-size: 31px;
      color: #fff;
    }
    .txt{
      font-weight: 600;
      font-size: 19px;
      color: #fff;
      //line-height: 40px;
      width: 134px;
      text-align: center;
    }
  }
}
.home2{
  background-size: 100%;
  //height: 586px;
  .h5{
    font-weight: 600;
    font-size: 31px;
    color: #FFFFFF;
    padding: 33px 0 39px;
    text-align: center;
  }
  .block3{
    margin: 0 20px;
    .list{
      flex-wrap: wrap;
      align-items: flex-start;
      .li{
        margin-bottom: 30px;
        img{
          width: 63px;
          height: 63px;
          margin-bottom: 11px;
        }
        .info{
          width: 152px;
          .label{
            font-size: 23px;
            color: #fff;
           margin-bottom: 11px;
            text-align: center;
          }
          .txt{
            text-align: center;
            font-size: 15px;
            color: #fff;
          }
        }
      }
      .home2Right{
        img{
          width: 100px;
          height: 100px;
        }

      }
    }
  }
}
.home3{
  background-size: 100%;
  //height: 440px;
  .h5{
    font-weight: 600;
    font-size: 31px;
    color: #202020;
    padding: 70px 0 38px ;
    text-align: center;
  }
  .block4{
    margin: 0 10px 0 20px;
  }
  .list{
    flex-wrap: wrap;
    .li{
      height: 62px;
      margin-bottom: 13px;
      background: #FFFFFF;
      box-shadow: 0px 0 13px 7px rgba(0,0,0,0.05);
      border-radius: 13px 13px 13px 13px;
      padding: 0 20px;
      margin-right: 10px;
      //min-width: 162px;
      flex: 1;
      img{
        width: 39px;
        height: 39px;
        margin-right: 16px;
      }
      text-align: left;
      font-weight: 600;
      font-size: 15px;
      color: #202020;
      white-space: nowrap;
      //margin-right: 100px;

    }
    //.li:nth-child(2n){
    //  margin-right: 0;
    //}
  }
}
</style>
