<template>
  <div class="">
    <div class="block">
      <el-carousel height="240px">
        <el-carousel-item v-for="(item, index) in img_arr" :key="index">
          <div class="item">
            <img :src="item.current" style="width: 100%;height: 240px"  />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="block1">
        <div class="h5">{{ $t('message.出海首选建站平台') }}</div>
        <div class="list">
          <div class="li">
            <div class="info">
              <div class="no row-s">
                <span>01</span><div style="width: 83px;height: 2px;background: #000;margin: 0 10px"></div>
              </div>
              <div class="label">{{ $t('message.快速搭建跨境独立站') }}</div>
              <div class="infoContent">
                  <div>• {{ $t('message.H5+移动端') }}</div>
                  <div>• {{ $t('message.多行业独立站精美模板免费套用') }}</div>
                  <div>• {{ $t('message.网站自动适配移动端和web端') }}</div>
              </div>
            </div>
            <img src="@/assets/hw1.png" />
          </div>
          <div class="li">
            <div class="info">
              <div class="no row-s">
                <span>02</span><div style="width: 83px;height: 2px;background: #000;margin: 0 10px"></div>
              </div>
              <div class="label">
                {{ $t('message.支持本地多种货币') }}
              </div>
              <div class="infoContent">
                <div>• {{ $t('message.微信支付宝') }}</div>
                <div>• {{ $t('message.银行卡支付') }}</div>
                <div>• {{ $t('message.10种语言') }}</div>
              </div>
            </div>
            <img src="@/assets/hw2.png" />
          </div>
          <div class="li">
            <div class="info">
              <div class="no row-s">
                <span>03</span><div style="width: 83px;height: 2px;background: #000;margin: 0 10px"></div>
              </div>
              <div class="label">{{ $t('message.丰富的营销玩法') }}</div>
              <div class="infoContent">
                <div>• {{ $t('message.优惠券') }}</div>
                <div>• {{ $t('message.一键分享微信') }}</div>
              </div>
            </div>
            <img src="@/assets/hw3.png" />
          </div>
          <div class="li">
            <div class="info">
              <div class="no row-s">
                <span>04</span><div style="width: 83px;height: 2px;background: #000;margin: 0 10px"></div>
              </div>
              <div class="label">
                {{ $t('message.更好的消费与分享') }}
              </div>
              <div class="infoContent">
                <div>• {{ $t('message.消费得积分') }}</div>
                <div>• {{ $t('message.搭建二级分销体系') }}</div>
              </div>
            </div>
            <img src="@/assets/hw4.png" />
          </div>
          <div class="li">
            <div class="info">
              <div class="no row-s">
                <span>05</span><div style="width: 83px;height: 2px;background: #000;margin: 0 10px"></div>
              </div>
              <div class="label">{{ $t('message.酒店餐饮赋能') }}</div>
              <div class="infoContent">
                <div>• {{ $t('message.酒店新零售') }}</div>
                <div>• {{ $t('message.自助扫码点餐') }}</div>
              </div>
            </div>
            <img src="@/assets/hw5.png" />
          </div>
          <div class="li">
            <div class="info">
              <div class="no row-s">
                <span>06</span><div style="width: 83px;height: 2px;background: #000;margin: 0 10px"></div>
              </div>
              <div class="label">{{ $t('message.专业团队') }}</div>
              <div class="infoContent">
                <div>• {{ $t('message.强大的研发实力') }}</div>
                <div>• {{ $t('message.团队深耕东南亚市场多年') }}</div>
              </div>
            </div>
            <img src="@/assets/hw6.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="box4" :style="{ backgroundImage: `url(${a3})`}">
      <div class="label">{{ $t('message.现在加入我们') }}</div>
      <div class="row-c">
        <div class="freeStoreOpen" @click="freeStoreOpen">
          {{ $t('message.免费开店') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tab",
  data() {
    return {
      img_arr: [
        {
          img: {
            En: require("../assets/MobileImage5_en.png"),
            zh: require("../assets/MobileImage5.png"),
          },
          current: '', // will be updated in created() method
        },],
      a3:require("@/assets/goBg2.png"),
    };
  },
  props:{
    lang: {
      type:String,
      default:'zh'
    }
  },
  watch:{
    lang(newValue){
      for (const item of this.img_arr) {
        item.current = item.img[newValue]
      }
    }
  },
  created() {
    for (const item of this.img_arr) {
      item.current = item.img[this.lang]
    }
  },
  computed: {

  },
  methods:{
    freeStoreOpen(){
      window.open('https://selleradmin.whatsbuy.com/#/login?register=true')
    },
  },
};
</script>

<style scoped lang="less">
.content{
  .block1{
    margin: 0 20px;
    .h5{
      margin: 52px 0 40px 0;
      font-weight: 600;
      font-size: 31px;
      color: #202020;
      text-align: center;
    }
    .list{
      .li{
        align-items: flex-start;
        margin-bottom: 20px;
        .info{

          .no{
            font-size: 27px;
            color: #202020;
            margin-bottom: 10px;
            font-weight: 600;
          }
          .label{
            font-weight: 600;
            font-size: 19px;
            color: #202020;
            margin-bottom: 5px;
          }
          .infoContent{
            width: 333px;
            font-weight: 400;
            font-size: 15px;
            color: #000000;
            line-height: 30px;
          }
        }
        img{
          width: 314px;
          height: 269px;
          margin-top: 27px;
        }
      }
    }
  }
}
.box4{
  height: 293px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //background: linear-gradient( 90deg, #9A40F4 0%, #33A8FF 100%);
  .label{
    font-weight: 700;
    font-size: 25px;
    color: #FFFFFF;
    padding-top: 58px;
    width: 307px;
    text-align: center;
    margin-left: 30px;
  }
  .freeStoreOpen{
    height: 46px;
    background: #FFFFFF;
    box-shadow: 0px 2 8px 0px rgba(0,60,102,0.4);
    border-radius: 4px 4px 4px 4px;
    padding: 0 20px;
    font-weight: 600;
    font-size: 19px;
    color: #9A40F4;
    line-height: 46px;
    margin-top: 43px;
  }
}
</style>
