<template>
  <div class="">
    <div class="block">
      <el-carousel height="240px">
        <el-carousel-item v-for="(item, index) in img_arr" :key="index">
          <div class="item">
            <img :src="item.current" style="width: 100%;height: 240px" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="block1">
        <div class="h5">{{ $t('message.关于WhatsBuy') }}</div>
        <div class="label">{{ $t('message.WhatsBuy为长沙好滴科技有限公司所有') }}</div>
        <div class="txt">{{ $t('message.哇呗') }}</div>
      </div>
    </div>
    <div class="home1" :style="{ backgroundImage: `url(${a1})`}">
      <div class="block1">
          <div class="h5">{{ $t('message.联系我们') }}</div>
          <div class="list row-b">
            <div class="li row-s">
              <img src="@/assets/about1.png" />
              <div class="info">
                <div class="label">{{ $t('message.地址') }}：</div>
                <div class="txt">{{ $t('message.湖南省长沙市岳麓区街道中建智慧产业园') }}</div>
              </div>
            </div>
            <div class="li row-s">
              <img src="@/assets/about2.png" />
              <div class="info">
                <div class="label">{{ $t('message.邮箱') }}：</div>
                <div class="txt">xishiwhatsbuy@gmail.com</div>
              </div>
            </div>
            <div class="li row-s">
              <img src="@/assets/about3.png" />
              <div class="info">
                <div class="label">{{ $t('message.联系电话') }}：</div>
                <div class="txt">0731-8550-8601</div>
              </div>
            </div>
            <div class="li row-s">
              <img src="@/assets/about4.png" />
              <div class="info">
                <div class="label">{{ $t('message.周一至周五') }}：</div>
                <div class="txt">09:00-18:00</div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tab",
  data() {
    return {
      a1:require("@/assets/aboutUsBg.png"),
      img_arr:[
        {
          img: {
            En: require("../assets/MobileImage7_en.png"),
            zh: require("../assets/MobileImage7.png"),
          },
          current: '', // will be updated in created() method
        },
      ],
    };
  },
  props:{
    lang: {
      type:String,
      default:'zh'
    }
  },
  watch:{
    lang(newValue){
      for (const item of this.img_arr) {
        item.current = item.img[newValue]
      }
    }
  },
  created(){
    for (const item of this.img_arr) {
      item.current = item.img[this.lang]
    }
  },
  computed: {

  },
  methods:{

  },
};
</script>

<style scoped lang="less">
.content{
  .block1{
    padding: 0 20px 20px;
    .h5{
      padding: 51px 0 28px 0;
      font-weight: 600;
      font-size: 31px;
      color: #202020;
      text-align: center;

    }
    .label{
      font-weight: 400;
      font-size: 23px;
      color: #202020;
      line-height: 30px;
      text-indent: 2em;
    }
    .txt{
      font-weight: 400;
      font-size: 23px;
      color: #202020;
      line-height: 30px;
      text-indent: 2em;
    }

  }
}
.home1{
  height: 649px;
  background-size: 100%;
  .block1{
    padding: 0 30px;
    .h5{
      font-weight: 600;
      font-size: 31px;
      color: #202020;
      text-align: center;
      padding: 49px 0 45px;
    }
    .list{
      flex-wrap: wrap;
      .li{
        align-items: flex-start;
        margin-bottom: 58px;
        img{
          width: 31px;
          height: 31px;
          margin-right: 27px;
        }
        .info{
          .label{
            font-weight: 700;
            font-size: 21px;
            color: #000000;
            margin-bottom: 10px;
          }
          .txt{
            width: 229px;
            font-weight: 400;
            font-size: 17px;
            color: #8F8F8F;
          }
        }
      }
    }
  }
}
</style>
