import request from '@/utils/request'

export function listHelpPage(params) {
    return request({
        url: '/threeTerminalCon/homeHelpCon/listHelpPage',
        method: 'post',
        params
    })
}
export function getContent4Id(params) {
    return request({
        url: '/threeTerminalCon/homeHelpCon/getContent4Id',
        method: 'post',
        params
    })
}
