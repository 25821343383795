<template>
  <div class="bigBox">
    <div class="block">
      <el-carousel height="779px">
        <el-carousel-item v-for="(item, index) in img_arr" :key="index">
          <div class="item">
            <img :src="item.current" style="width: 100%;height: 779px" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="block1">
        <div class="h5">{{ $t('message.产品中心') }}</div>
          <div class="monitor-list">
            <!-- 左边按钮 -->
            <div class="btn" @click="scrollLeft" style="left: 25px">
              <i class="el-icon el-icon-arrow-left" />
            </div>
            <!-- 中间列表 -->
            <div id="list-box" class="list-box">
              <div id="list" class="list">
                <div class="list-item">
                    <img src="@/assets/wugeshoumaiji.png" />
                    <div class="info">
                      {{ $t('message.5格售卖机') }}
                    </div>
<!--                    <div class="readMore row-s">Read more <i class="el-icon el-icon-right" style="font-size: 24px;margin-left: 5px" /></div>-->
                </div>
                <div class="list-item">
                  <img src="@/assets/wugeshoujmaiji2.png" />
                  <div class="info">
                    {{ $t('message.5格售卖机') }}
                  </div>
<!--                  <div class="readMore row-s">Read more <i class="el-icon el-icon-right" style="font-size: 24px;margin-left: 5px" /></div>-->
                </div>
                <div class="list-item">
                  <img src="@/assets/liugeshoumaiji.png" />
                  <div class="info">
                    {{ $t('message.6格售卖机') }}
                  </div>
<!--                  <div class="readMore row-s">Read more <i class="el-icon el-icon-right" style="font-size: 24px;margin-left: 5px" /></div>-->
                </div>
                <div class="list-item">
                  <img src="@/assets/qigeshoumaiji.png" />
                  <div class="info">
                    {{ $t('message.7格售卖机') }}
                  </div>
<!--                  <div class="readMore row-s">Read more <i class="el-icon el-icon-right" style="font-size: 24px;margin-left: 5px" /></div>-->
                </div>
              </div>
            </div>
            <!-- 右边按钮 -->
            <div class="btn" @click="scrollRight" style="right: 20px">
              <i class="el-icon el-icon-arrow-right" />
            </div>
          </div>
      </div>
    </div>
    <div class="home1 row-b" :style="{ backgroundImage: `url(${a1})`}">
        <div class="info">
          <div class="h5">{{ $t('message.产品特点') }}</div>
          <div class="introduction">{{ $t('message.小型酒店售货机是一种基于互联网和智能技术的自动售货机') }}</div>
          <div class="txt">
            {{ $t('message.可帮助酒店提升旅客体验') }}
          </div>
        </div>
      <div class="leftBox">
        <div class="box1 row-c row-d">
          <div class="order">01</div>
          <div class="txt">{{ $t('message.立式橱窗') }}</div>
        </div>
        <div class="box2 row-c row-d">
          <div class="order">02</div>
          <div class="txt">{{ $t('message.电池/电源供电') }}</div>
        </div>
        <div class="box3 row-c row-d">
          <div class="order">03</div>
          <div class="txt">{{ $t('message.蓝牙连接') }}</div>
        </div>
        <div class="box4 row-c row-d">
          <div class="order">04</div>
          <div class="txt">{{ $t('message.多种配色可选') }}</div>
        </div>
        <div class="box5 row-c row-d">
          <div class="order">05</div>
          <div class="txt">{{ $t('message.缺货提醒') }}</div>
        </div>
        <div class="box6 row-c row-d">
          <div class="box7 row-c row-d">
            <div class="order">06</div>
            <div class="txt" >{{ $t('message.手机扫码支付') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home2" :style="{ backgroundImage: `url(${a2})`}">
        <div class="h5">{{ $t('message.我们的优势') }}</div>
        <div class="block3">
              <div class="list row-a">
                <div class="li row-c row-d">
                  <img src="@/assets/advantage1.png" />
                  <div class="info">
                    <div class="label">{{ $t('message.服务周到') }}</div>
                    <div class="txt">{{ $t('message.一站式服务') }}</div>
                  </div>
                </div>
                <div class="home2Right">
                  <img src="@/assets/vendingright.png" />
                </div>
                <div class="li row-c row-d">
                  <img src="@/assets/advantage2.png" />
                  <div class="info">
                    <div class="label">{{ $t('message.性能优秀') }}</div>
                    <div class="txt">{{ $t('message.设备性能稳定便捷') }}</div>
                  </div>
                </div>
                <div class="home2Right">
                  <img src="@/assets/vendingright.png" />
                </div>
                <div class="li row-c row-d">
                  <img src="@/assets/advantage3.png" />
                  <div class="info">
                    <div class="label">{{ $t('message.价格更低') }}</div>
                    <div class="txt">{{ $t('message.量大价格低') }}</div>
                  </div>
                </div>
                <div class="home2Right">
                  <img src="@/assets/vendingright.png" />
                </div>
                <div class="li row-c row-d">
                  <img src="@/assets/advantage4.png" />
                  <div class="info">
                    <div class="label">{{ $t('message.互利分成') }}</div>
                    <div class="txt">{{ $t('message.酒店合作联营') }}</div>
                  </div>
                </div>

              </div>
        </div>
    </div>

    <div class="home3" :style="{ backgroundImage: `url(${a3})`}">
      <div class="h5">{{ $t('message.合作流程') }}</div>
      <div class="block4">
        <div class="list row-b">
          <div class="li row-s">
            <img src="@/assets/cooperation1.png" />
            {{ $t('message.在线咨询') }}
          </div>
          <div class="li row-s">
            <img src="@/assets/cooperation2.png" />
            {{ $t('message.了解需求') }}
          </div>
          <div class="li row-s">
            <img src="@/assets/cooperation3.png" />
            {{ $t('message.制定方案') }}
          </div>
          <div class="li row-s">
            <img src="@/assets/cooperation4.png" />
            {{ $t('message.签订合同') }}
          </div>
          <div class="li row-s">
            <img src="@/assets/cooperation5.png" />
            {{ $t('message.投入使用') }}
          </div>
          <div class="li row-s">
            <img src="@/assets/cooperation6.png" />
            {{ $t('message.售后维护') }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props:{
    lang: {
      type:String,
      default:'zh'
    }
  },
  watch:{
    lang(newValue){
      for (const item of this.img_arr) {
        item.current = item.img[newValue]
      }

    }
  },
  data() {
    return {
      monitorList: [
        {img:'',title:this.$t('message.5格售卖机')},
        {img:'',title:this.$t('message.6格售卖机')},
        {img:'',title:this.$t('message.7格售卖机')},
        {img:'',title:this.$t('message.8格售卖机')},
      ],
      a1:require("@/assets/vendingMachineBg.png"),
      a2:require("@/assets/vendingMachineHome2.png"),
      a3:require("@/assets/vendingMachineHome3.png"),
      img_arr: [
        {
          img: {
            En: require("../assets/image4_en.png"),
            zh: require("../assets/image4.png"),
          },
          current: '', // will be updated in created() method
        },
      ],
    };
  },
  mounted() {
    this.initMonitorList()
  },
  created() {
    for (const item of this.img_arr) {
      item.current = item.img[this.lang]
    }
  },
  methods:{
    initMonitorList() {
      for (let i = 1; i < 6; i++) {
        this.monitorList.push({
          id: i,
          name: `item${i + 1}`,
          status: 0
        })
      }
    },
    // 左滑动逻辑
    scrollLeft() {
      const allLength = this.monitorList.length * 441
      const boxLength = document.getElementById('list-box').clientWidth
      if (allLength < boxLength) return
      const listEl = document.getElementById('list')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      if (leftMove + boxLength - 1323 < boxLength) {
        // 到最开始的时候
        listEl.style.left = '0px'
      } else {
        listEl.style.left = '-' + (leftMove - 1323) + 'px'
      }
    },
    // 右滑动逻辑
    scrollRight() {
      const allLength = this.monitorList.length * 320
      const boxLength = document.getElementById('list-box').clientWidth
      console.log(allLength,'allLength',boxLength)

      if (allLength < boxLength) return
      const listEl = document.getElementById('list')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      if (leftMove + boxLength + 1323 > allLength) {
        listEl.style.left = '-' + (allLength - boxLength) + 'px'
      } else {
        listEl.style.left = '-' + (leftMove + 1323) + 'px'
      }
    }

  },
};
</script>

<style scoped lang="less">
.bigBox{
  zoom: 80%;
}
.content{
  background: #F6FAFF;
  padding-bottom: 74px;
  .block1{
    margin: 0 170px;
    .h5{
      padding: 72px 0 77px 0;
      font-weight: 600;
      font-size: 40px;
      color: #202020;
      text-align: center;
    }
    .monitor-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      //height: 533px;

      .btn {
        position: absolute;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        background-color: #9c9c9c;
        flex-shrink: 0;
        font-size: 24px;
        color: #000;
        z-index: 1;
      }
      .list-box {
        width: calc(100vw - 100px);
        overflow: hidden;
        padding: 0 20px;
        .list {
          width: calc(100vw - 100px);
          display: flex;
          transform: all 2s;

          .list-item {
            width: 340px;
            height: 500px;
            //padding-bottom:30px ;
            background: #FFFFFF;
            box-shadow: 0px 0 13px 7px rgba(0,0,0,0.05);
            cursor: pointer;
            margin-right: 41px;
            flex-shrink: 0;
            padding: 30px 30px 0;
            img{
              width: 340px;
              height: 340px;
              background: #D9D9D9;
              border-radius: 10px 10px 10px 10px;
              margin-bottom: 24px;
            }
            .info{

              font-weight: 600;
              font-size: 24px;
              color: #202020;
              padding-bottom: 20px;
              height: 64px;
              border-bottom: 1px solid #D6D6D6;
            }
            .readMore{
              margin-top: 26px;
              font-weight: 600;
              font-size: 16px;
              color: #9A40F4;
            }
          }
          position: relative;
          left: 0;
          transition: left 1s;
        }
      }
    }
  }
}
.home1{
  background-size: 100%;
  height: 1300px;
  position: relative;
  .info{
    position: absolute;
    top: 205px;
    left: 173px;
    .h5{
      font-weight: 600;
      font-size: 53px;
      color: #202020;
      margin-bottom: 37px;
    }
    .introduction{
      font-weight: 600;
      font-size: 32px;
      color: #202020;
      width: 480px;
      margin-bottom:26px ;
      line-height:40px;
    }
    .txt{
      font-weight: 400;
      font-size: 28px;
      color: #202020;
      width: 468px;
      line-height:45px;
    }
  }
  .box1{
    position: absolute;
    left: 1012px;
    top: 180px;
    width: 230px;
    height: 230px;
    background: #FFFFFF;
    border: 4px solid #9A40F4;
    border-radius: 100%;
    z-index: 1;
    .order{
      font-weight: 600;
      font-size: 19px;
      color: #202020;
      margin-bottom: 27px;
    }
    .txt{
      font-weight: 600;
      font-size: 16px;
      color: #202020;
      line-height: 40px;
      width: 200px;
      text-align: center;
    }
  }

  .box2{
    position: absolute;
    left: 1369px;
    top: 267px;
    width: 300px;
    height: 300px;
    background: #FFFFFF;
    border: 4px solid #9A40F4;
    border-radius: 100%;
    z-index: 1;
    .order{
      font-weight: 600;
      font-size: 19px;
      color: #202020;
      margin-bottom: 27px;
    }
    .txt{
      font-weight: 600;
      font-size: 16px;
      color: #202020;
      line-height: 40px;
      width: 247px;
      text-align: center;
    }
  }

  .box3{
    position: absolute;
    left: 1369px;
    top: 687px;
    width: 280px;
    height: 280px;
    background: #FFFFFF;
    border: 4px solid #9A40F4;
    border-radius: 100%;
    z-index: 1;
    .order{
      font-weight: 600;
      font-size: 19px;
      color: #202020;
      margin-bottom: 27px;
    }
    .txt{
      font-weight: 600;
      font-size: 16px;
      color: #202020;
      line-height: 40px;
      width: 233px;
      text-align: center;
    }
  }

  .box4{
    position: absolute;
    left: 1025px;
    top: 896px;
    width: 290px;
    height: 290px;
    background: #FFFFFF;
    border: 4px solid #9A40F4;
    border-radius: 100%;
    z-index: 1;
    .order{
      font-weight: 600;
      font-size: 19px;
      color: #202020;
      margin-bottom: 27px;
    }
    .txt{
      font-weight: 600;
      font-size: 16px;
      color: #202020;
      line-height: 40px;
      width: 247px;
      text-align: center;
    }
  }

  .box5{
    position: absolute;
    left: 681px;
    top: 801px;
    width: 243px;
    height: 243px;
    background: #FFFFFF;
    border: 4px solid #9A40F4;
    border-radius: 100%;
    z-index: 1;
    .order{
      font-weight: 600;
      font-size: 19px;
      color: #202020;
      margin-bottom: 27px;
    }
    .txt{
      font-weight: 600;
      font-size: 16px;
      color: #202020;
      line-height: 40px;
      width: 165px;
      text-align: center;
    }
  }

  .box6{
    position: absolute;
    left: 708px;
    top: 332px;
    width: 632px;
    height: 632px;
    background: #C4C4C4;
    border-radius: 100%;
    z-index: 0;
    .box7{
      width: 362px;
      height: 362px;
      background: #9A40F4;
      border: 4px solid #fff;
      border-radius: 100%;
    }
    .order{
      font-weight: 600;
      font-size: 50px;
      color: #fff;
    }
    .txt{
      font-weight: 600;
      font-size: 16px;
      color: #fff;
      line-height: 40px;
      width: 280px;
      text-align: center;
    }
  }
}
.home2{
  background-size: 100%;
  height: 692px;
  .h5{
    font-weight: 600;
    font-size: 53px;
    color: #FFFFFF;
    padding: 105px 0 99px;
    text-align: center;
  }
  .block3{
    margin: 0 170px;
    .list{
      .li{
        img{
          width: 120px;
          height: 120px;
          margin-bottom: 15px;
        }
        .info{
          width: 260px;
          .label{
            font-size: 30px;
            color: #fff;
           margin-bottom: 15px;
            text-align: center;
          }
          .txt{
            text-align: center;
            font-size: 20px;
            color: #fff;
          }
        }
      }
      .home2Right{
        img{
          width: 100px;
          height: 100px;
        }

      }
    }
  }
}
.home3{
  background-size: 100%;
  height: 835px;
  .h5{
    font-weight: 600;
    font-size: 53px;
    color: #202020;
    padding: 117px 0 80px ;
    text-align: center;
  }
  .block4{
    margin: 0 170px;
  }
  .list{
    flex-wrap: wrap;
    .li{
      height: 133px;
      margin-bottom: 27px;
      background: #FFFFFF;
      box-shadow: 0px 0 13px 7px rgba(0,0,0,0.05);
      border-radius: 13px 13px 13px 13px;
      padding: 0 183px 0 89px;
      width: 400px;
      margin-right: 30px;
      img{
        width: 83px;
        height: 83px;
        margin-right: 61px;
      }
      text-align: left;
      font-weight: 600;
      font-size: 29px;
      color: #202020;
      white-space: nowrap;
      //margin-right: 100px;

    }
    .li:nth-child(2n){
      margin-right: 0;
    }
  }
}
@media  screen and (min-width:1650px)and (max-width: 1657px){
  .bigBox{
    zoom: 80%;
  }
  .content{
    background: #F6FAFF;
    padding-bottom: 74px;
    .block1{
      margin: 0 170px;
      .h5{
        padding: 72px 0 77px 0;
        font-weight: 600;
        font-size: 40px;
        color: #202020;
        text-align: center;
      }
      .monitor-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        //height: 533px;

        .btn {
          position: absolute;
          width: 36px;
          height: 36px;
          border-radius: 100%;
          line-height: 36px;
          text-align: center;
          cursor: pointer;
          background-color: #9c9c9c;
          flex-shrink: 0;
          font-size: 24px;
          color: #000;
          z-index: 1;
        }
        .list-box {
          width: calc(100vw - 100px);
          overflow: hidden;
          padding: 0 20px;
          .list {
            width: calc(100vw - 100px);
            display: flex;
            transform: all 2s;

            .list-item {
              width: 340px;
              height: 500px;
              //padding-bottom:30px ;
              background: #FFFFFF;
              box-shadow: 0px 0 13px 7px rgba(0,0,0,0.05);
              cursor: pointer;
              margin-right: 41px;
              flex-shrink: 0;
              padding: 30px 30px 0;
              img{
                width: 340px;
                height: 340px;
                background: #D9D9D9;
                border-radius: 10px 10px 10px 10px;
                margin-bottom: 24px;
              }
              .info{

                font-weight: 600;
                font-size: 24px;
                color: #202020;
                padding-bottom: 20px;
                height: 64px;
                border-bottom: 1px solid #D6D6D6;
              }
              .readMore{
                margin-top: 26px;
                font-weight: 600;
                font-size: 16px;
                color: #9A40F4;
              }
            }
            position: relative;
            left: 0;
            transition: left 1s;
          }
        }
      }
    }
  }
  .home1{
    background-size: 100%;
    height: 1300px;
    position: relative;
    .info{
      position: absolute;
      top: 205px;
      left: 173px;
      .h5{
        font-weight: 600;
        font-size: 53px;
        color: #202020;
        margin-bottom: 37px;
      }
      .introduction{
        font-weight: 600;
        font-size: 32px;
        color: #202020;
        width: 480px;
        margin-bottom:26px ;
        line-height:40px;
      }
      .txt{
        font-weight: 400;
        font-size: 28px;
        color: #202020;
        width: 468px;
        line-height:45px;
      }
    }
    .leftBox{
      position: absolute;
      left: 400px;
      top: 0px;
    }
    .box1{
      position: absolute;
      left: 1012px;
      top: 180px;
      width: 230px;
      height: 230px;
      background: #FFFFFF;
      border: 4px solid #9A40F4;
      border-radius: 100%;
      z-index: 1;
      .order{
        font-weight: 600;
        font-size: 19px;
        color: #202020;
        margin-bottom: 27px;
      }
      .txt{
        font-weight: 600;
        font-size: 16px;
        color: #202020;
        line-height: 40px;
        width: 200px;
        text-align: center;
      }
    }

    .box2{
      position: absolute;
      left: 1369px;
      top: 267px;
      width: 300px;
      height: 300px;
      background: #FFFFFF;
      border: 4px solid #9A40F4;
      border-radius: 100%;
      z-index: 1;
      .order{
        font-weight: 600;
        font-size: 19px;
        color: #202020;
        margin-bottom: 27px;
      }
      .txt{
        font-weight: 600;
        font-size: 16px;
        color: #202020;
        line-height: 40px;
        width: 247px;
        text-align: center;
      }
    }

    .box3{
      position: absolute;
      left: 1369px;
      top: 687px;
      width: 280px;
      height: 280px;
      background: #FFFFFF;
      border: 4px solid #9A40F4;
      border-radius: 100%;
      z-index: 1;
      .order{
        font-weight: 600;
        font-size: 19px;
        color: #202020;
        margin-bottom: 27px;
      }
      .txt{
        font-weight: 600;
        font-size: 16px;
        color: #202020;
        line-height: 40px;
        width: 233px;
        text-align: center;
      }
    }

    .box4{
      position: absolute;
      left: 1025px;
      top: 896px;
      width: 290px;
      height: 290px;
      background: #FFFFFF;
      border: 4px solid #9A40F4;
      border-radius: 100%;
      z-index: 1;
      .order{
        font-weight: 600;
        font-size: 19px;
        color: #202020;
        margin-bottom: 27px;
      }
      .txt{
        font-weight: 600;
        font-size: 16px;
        color: #202020;
        line-height: 40px;
        width: 247px;
        text-align: center;
      }
    }

    .box5{
      position: absolute;
      left: 681px;
      top: 801px;
      width: 243px;
      height: 243px;
      background: #FFFFFF;
      border: 4px solid #9A40F4;
      border-radius: 100%;
      z-index: 1;
      .order{
        font-weight: 600;
        font-size: 19px;
        color: #202020;
        margin-bottom: 27px;
      }
      .txt{
        font-weight: 600;
        font-size: 16px;
        color: #202020;
        line-height: 40px;
        width: 165px;
        text-align: center;
      }
    }

    .box6{
      position: absolute;
      left: 708px;
      top: 332px;
      width: 632px;
      height: 632px;
      background: #C4C4C4;
      border-radius: 100%;
      z-index: 0;
      .box7{
        width: 362px;
        height: 362px;
        background: #9A40F4;
        border: 4px solid #fff;
        border-radius: 100%;
      }
      .order{
        font-weight: 600;
        font-size: 50px;
        color: #fff;
      }
      .txt{
        font-weight: 600;
        font-size: 16px;
        color: #fff;
        line-height: 40px;
        width: 280px;
        text-align: center;
      }
    }
  }
  .home2{
    background-size: 100%;
    height: 692px;
    .h5{
      font-weight: 600;
      font-size: 53px;
      color: #FFFFFF;
      padding: 105px 0 99px;
      text-align: center;
    }
    .block3{
      margin: 0 170px;
      .list{
        .li{
          img{
            width: 120px;
            height: 120px;
            margin-bottom: 15px;
          }
          .info{
            width: 260px;
            .label{
              font-size: 30px;
              color: #fff;
              margin-bottom: 15px;
              text-align: center;
            }
            .txt{
              text-align: center;
              font-size: 20px;
              color: #fff;
            }
          }
        }
        .home2Right{
          img{
            width: 100px;
            height: 100px;
          }

        }
      }
    }
  }
  .home3{
    background-size: 100%;
    //height: 835px;
    .h5{
      font-weight: 600;
      font-size: 53px;
      color: #202020;
      padding: 117px 0 80px ;
      text-align: center;
    }
    .block4{
      margin: 0 400px;
    }
    .list{
      flex-wrap: wrap;
      .li{
        height: 133px;
        margin-bottom: 27px;
        background: #FFFFFF;
        box-shadow: 0px 0 13px 7px rgba(0,0,0,0.05);
        border-radius: 13px 13px 13px 13px;
        padding: 0 183px 0 89px;
        width: 350px;
        margin-right: 30px;
        img{
          width: 83px;
          height: 83px;
          margin-right: 61px;
        }
        text-align: left;
        font-weight: 600;
        font-size: 29px;
        color: #202020;
        white-space: nowrap;
        //margin-right: 100px;

      }
      .li:nth-child(2n){
        margin-right: 0;
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1550px){
  .bigBox{
    zoom: 60%;
  }
  .content{
    background: #F6FAFF;
    padding-bottom: 74px;
    .block1{
      margin: 0 170px;
      .h5{
        padding: 72px 0 77px 0;
        font-weight: 600;
        font-size: 40px;
        color: #202020;
        text-align: center;
      }
      .monitor-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        //height: 533px;

        .btn {
          position: absolute;
          width: 36px;
          height: 36px;
          border-radius: 100%;
          line-height: 36px;
          text-align: center;
          cursor: pointer;
          background-color: #9c9c9c;
          flex-shrink: 0;
          font-size: 24px;
          color: #000;
          z-index: 1;
        }
        .list-box {
          width: calc(100vw - 100px);
          overflow: hidden;
          padding: 0 20px;
          .list {
            width: calc(100vw - 100px);
            display: flex;
            transform: all 2s;

            .list-item {
              width: 340px;
              height: 500px;
              //padding-bottom:30px ;
              background: #FFFFFF;
              box-shadow: 0px 0 13px 7px rgba(0,0,0,0.05);
              cursor: pointer;
              margin-right: 41px;
              flex-shrink: 0;
              padding: 30px 30px 0;
              img{
                width: 340px;
                height: 340px;
                background: #D9D9D9;
                border-radius: 10px 10px 10px 10px;
                margin-bottom: 24px;
              }
              .info{

                font-weight: 600;
                font-size: 24px;
                color: #202020;
                padding-bottom: 20px;
                height: 64px;
                border-bottom: 1px solid #D6D6D6;
              }
              .readMore{
                margin-top: 26px;
                font-weight: 600;
                font-size: 16px;
                color: #9A40F4;
              }
            }
            position: relative;
            left: 0;
            transition: left 1s;
          }
        }
      }
    }
  }
  .home1{
    background-size: 100%;
    height: 1300px;
    position: relative;
    .info{
      position: absolute;
      top: 205px;
      left: 173px;
      .h5{
        font-weight: 600;
        font-size: 53px;
        color: #202020;
        margin-bottom: 37px;
      }
      .introduction{
        font-weight: 600;
        font-size: 32px;
        color: #202020;
        width: 480px;
        margin-bottom:26px ;
        line-height:40px;
      }
      .txt{
        font-weight: 400;
        font-size: 28px;
        color: #202020;
        width: 468px;
        line-height:45px;
      }
    }
    .leftBox{
      position: absolute;
      left: 400px;
      top: 0px;
    }
    .box1{
      position: absolute;
      left: 1012px;
      top: 180px;
      width: 230px;
      height: 230px;
      background: #FFFFFF;
      border: 4px solid #9A40F4;
      border-radius: 100%;
      z-index: 1;
      .order{
        font-weight: 600;
        font-size: 19px;
        color: #202020;
        margin-bottom: 27px;
      }
      .txt{
        font-weight: 600;
        font-size: 16px;
        color: #202020;
        line-height: 40px;
        width: 200px;
        text-align: center;
      }
    }

    .box2{
      position: absolute;
      left: 1369px;
      top: 267px;
      width: 300px;
      height: 300px;
      background: #FFFFFF;
      border: 4px solid #9A40F4;
      border-radius: 100%;
      z-index: 1;
      .order{
        font-weight: 600;
        font-size: 19px;
        color: #202020;
        margin-bottom: 27px;
      }
      .txt{
        font-weight: 600;
        font-size: 16px;
        color: #202020;
        line-height: 40px;
        width: 247px;
        text-align: center;
      }
    }

    .box3{
      position: absolute;
      left: 1369px;
      top: 687px;
      width: 280px;
      height: 280px;
      background: #FFFFFF;
      border: 4px solid #9A40F4;
      border-radius: 100%;
      z-index: 1;
      .order{
        font-weight: 600;
        font-size: 19px;
        color: #202020;
        margin-bottom: 27px;
      }
      .txt{
        font-weight: 600;
        font-size: 16px;
        color: #202020;
        line-height: 40px;
        width: 233px;
        text-align: center;
      }
    }

    .box4{
      position: absolute;
      left: 1025px;
      top: 896px;
      width: 290px;
      height: 290px;
      background: #FFFFFF;
      border: 4px solid #9A40F4;
      border-radius: 100%;
      z-index: 1;
      .order{
        font-weight: 600;
        font-size: 19px;
        color: #202020;
        margin-bottom: 27px;
      }
      .txt{
        font-weight: 600;
        font-size: 16px;
        color: #202020;
        line-height: 40px;
        width: 247px;
        text-align: center;
      }
    }

    .box5{
      position: absolute;
      left: 681px;
      top: 801px;
      width: 243px;
      height: 243px;
      background: #FFFFFF;
      border: 4px solid #9A40F4;
      border-radius: 100%;
      z-index: 1;
      .order{
        font-weight: 600;
        font-size: 19px;
        color: #202020;
        margin-bottom: 27px;
      }
      .txt{
        font-weight: 600;
        font-size: 16px;
        color: #202020;
        line-height: 40px;
        width: 165px;
        text-align: center;
      }
    }

    .box6{
      position: absolute;
      left: 708px;
      top: 332px;
      width: 632px;
      height: 632px;
      background: #C4C4C4;
      border-radius: 100%;
      z-index: 0;
      .box7{
        width: 362px;
        height: 362px;
        background: #9A40F4;
        border: 4px solid #fff;
        border-radius: 100%;
      }
      .order{
        font-weight: 600;
        font-size: 50px;
        color: #fff;
      }
      .txt{
        font-weight: 600;
        font-size: 16px;
        color: #fff;
        line-height: 40px;
        width: 280px;
        text-align: center;
      }
    }
  }
  .home2{
    background-size: 100%;
    height: 692px;
    .h5{
      font-weight: 600;
      font-size: 53px;
      color: #FFFFFF;
      padding: 105px 0 99px;
      text-align: center;
    }
    .block3{
      margin: 0 170px;
      .list{
        .li{
          img{
            width: 120px;
            height: 120px;
            margin-bottom: 15px;
          }
          .info{
            width: 260px;
            .label{
              font-size: 30px;
              color: #fff;
              margin-bottom: 15px;
              text-align: center;
            }
            .txt{
              text-align: center;
              font-size: 20px;
              color: #fff;
            }
          }
        }
        .home2Right{
          img{
            width: 100px;
            height: 100px;
          }

        }
      }
    }
  }
  .home3{
    background-size: 100%;
    //height: 835px;
    .h5{
      font-weight: 600;
      font-size: 53px;
      color: #202020;
      padding: 117px 0 80px ;
      text-align: center;
    }
    .block4{
      margin: 0 350px;
    }
    .list{
      flex-wrap: wrap;
      .li{
        height: 133px;
        margin-bottom: 27px;
        background: #FFFFFF;
        box-shadow: 0px 0 13px 7px rgba(0,0,0,0.05);
        border-radius: 13px 13px 13px 13px;
        padding: 0 183px 0 89px;
        width: 350px;
        margin-right: 30px;
        img{
          width: 83px;
          height: 83px;
          margin-right: 61px;
        }
        text-align: left;
        font-weight: 600;
        font-size: 29px;
        color: #202020;
        white-space: nowrap;
        //margin-right: 100px;

      }
      .li:nth-child(2n){
        margin-right: 0;
      }
    }
  }
}

</style>
