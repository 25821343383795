<template>
  <div class="">
    <goBack :title="$route.query.title" ></goBack>
    <div class="content">
      <div v-html="content"></div>
    </div>

  </div>
</template>
<script>

import {getContent4Id} from "@/API/helpCenter";
import  goBack from "../components/goBack.vue"
export default {
  data() {
    return {
      actMenu:0,
      home1Index:0,
      content:'content',
      lang:'zh',
    };
  },
  components: {
    goBack
  },
  created() {
    localStorage.setItem('actMenu',3)
    // this.changeLanguage(this.$i18n.locale);
    this.getContent(this.$route.query.id)
  },
  destroyed() {
  },
  computed: {
  },

  methods: {
    async getContent(id){
      let res =await  getContent4Id({id:id})
      this.content=res.data
    },

  },
};
</script>

<style lang="less" scoped>
::v-deep img{

  max-width:100%;

}
.content{
  padding: 20px;
  //width: 100%;
}

</style>
