<template>
  <div class="">
    <div class="block">
      <el-carousel height="240px">
        <el-carousel-item v-for="(item, index) in img_arr" :key="index">
          <div class="item">
            <img :src="item.current" style="width: 100%;height: 240px"  />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content" :style="{ backgroundImage: `url(${a2})`}">
        <div>
          <ScrollView>
            <div class="row-s list">
              <div :class="['li',home1Index==0?'act':'']" @click="switchHome1(0)">
                {{ $t('message.新手建站') }}
              </div>
              <div :class="['li',home1Index==1?'act':'']" @click="switchHome1(1)">
                {{ $t('message.传统平台卖家') }}
              </div>
              <div :class="['li',home1Index==2?'act':'']" @click="switchHome1(2)">
                {{ $t('message.小额批发商') }}
              </div>
              <div :class="['li',home1Index==3?'act':'']" @click="switchHome1(3)">
                {{ $t('message.跨境SOHO') }}
              </div>
              <div :class="['li',home1Index==4?'act':'']" @click="switchHome1(4)">
                {{ $t('message.酒店/餐饮行业赋能人财') }}
              </div>
            </div>
          </ScrollView>
          <div class="homeInfo">
            <div class="contentH8">
              {{home1Index==0?$t('message.新手建站'):home1Index==1?$t('message.传统平台卖家'):home1Index==2?$t('message.小额批发商'):home1Index==3?$t('message.跨境SOHO'):$t('message.酒店/餐饮行业赋能人财')}}
            </div>
            <div class="contentTxt">
              {{home1Index==0?$t('message.我是个新手'):home1Index==1?$t('message.我是传统卖家'):home1Index==2?$t('message.我是小额批发商'):home1Index==3?$t('message.我是一名老站长'):$t('message.我是个酒店老板')}}
            </div>
            <div class="row-s">
              <div class="freeStoreOpen" @click="freeStoreOpen">
                {{ $t('message.免费开店') }}
              </div>
            </div>
            <img src="@/assets/homeImage.png" v-if="home1Index==0" />
            <img src="@/assets/homeImage1.png" v-if="home1Index==1" />
            <img src="@/assets/homeImage2.png" v-if="home1Index==2" />
            <img src="@/assets/homeImage3.png" v-if="home1Index==3" />
            <img src="@/assets/homeImage4.png" v-if="home1Index==4" />
          </div>
        </div>
    </div>
    <div class="box2">
      <div class="contentH8">{{ $t('message.我们能帮助您解决什么问题') }}</div>
      <div class="homeInfo row-b">
        <div class="li">
          <img src="@/assets/homeLogo1.png" />
          <div class="home2Info row-s row-d">
            <div class="txt">{{ $t('message.链接全球客户') }}</div>
            <div class="homeTxt">{{ $t('message.使用营销工具') }}</div>
          </div>
        </div>
        <div class="li ">
          <img src="@/assets/homeLogo2.png" />
          <div class="home2Info row-s row-d">
            <div class="txt">{{ $t('message.打造您的品牌') }}</div>
            <div class="homeTxt">{{ $t('message.创建自己的品牌名字') }}</div>
          </div>
        </div>
        <div class="li ">
          <img src="@/assets/homeLogo3.png" />
          <div class="home2Info row-s row-d">
            <div class="txt">{{ $t('message.丰富营销手段') }}</div>
            <div class="homeTxt">{{ $t('message.单一营销手段') }}</div>
          </div>
        </div>
        <div class="li ">
          <img src="@/assets/homeLogo4.png" />
          <div class="home2Info row-s row-d">
            <div class="txt">{{ $t('message.酒店/餐饮赋能') }}</div>
            <div class="homeTxt">{{ $t('message.上门送货') }}</div>
          </div>
        </div>
        <div class="li ">
          <img src="@/assets/homeLogo5.png" />
          <div class="home2Info row-s row-d">
            <div class="txt">{{ $t('message.收款困难') }}</div>
            <div class="homeTxt">{{ $t('message.跨境资金收款困难') }}</div>
          </div>
        </div>
        <div class="li ">
          <img src="@/assets/homeLogo6.png" />
          <div class="home2Info row-s row-d">
            <div class="txt">{{ $t('message.开发成本过高') }}</div>
            <div class="homeTxt">{{ $t('message.单独开发自有商城难度系数大') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="title">{{ $t('message.合作伙伴') }}</div>
      <div class="scroll-view" id="scrollable"  style="background: #eeeeef;">
        <div class="scrollable-content">
          <!-- Add your content here -->
          <div class="row-c topOne">
            <img src="@/assets/partner1.png" style="margin-right: 18px" />
            <img src="@/assets/partner2.png" />
          </div>
          <div class="row-c topTwo">
            <img src="@/assets/partner3.png"  />
            <img src="@/assets/partner4.png" style="margin:0 18px;" />
            <img src="@/assets/partner5.png" />
          </div>
          <div class="row-c topThree">
            <img src="@/assets/partner6.png" style="margin-right: 18px;" />
            <img src="@/assets/partner7.png" style="margin-right: 18px;" />
            <img src="@/assets/partner8.png" style="margin-right: 18px;" />
            <img src="@/assets/partner9.png" />
          </div>
          <div class="row-c topFive">
            <img src="@/assets/partner10.png" style="margin-right: 18px;" />
            <img src="@/assets/partner11.png" style="margin-right: 18px;" />
            <img src="@/assets/partner12.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="box4" :style="{ backgroundImage: `url(${a3})`}">
      <div class="label">{{ $t('message.现在加入我们') }}</div>
      <div class="row-c">
        <div class="freeStoreOpen" @click="freeStoreOpen">
          {{ $t('message.免费开店') }}
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import ScrollView from '@/components/scrollView'
export default {
  name: "Tab",
  components: {
    /* 注册子组件 */
    ScrollView
  },
  props:{
    lang: {
      type:String,
      default:'zh'
    }
  },
  watch:{
    lang(newValue){
      for (const item of this.img_arr) {
        item.current = item.img[newValue]
      }

    }
  },
  data() {
    return {
      home1Index:0,
      a1:require("@/assets/home2Bg.png"),
      a2:require("@/assets/homeMobileBg.png"),
      img_arr: [
        {
          img: {
            En: require("../assets/MobileImage1_en.png"),
            zh: require("../assets/MobileImage1.png"),
          },
          current: '', // will be updated in created() method
        },
        {
          img: {
            En: require("../assets/MobileImage2_en.png"),
            zh: require("../assets/MobileImage2.png"),
          },
          current: '', // will be updated in created() method
        },
        {
          img: {
            En: require("../assets/MobileImage3_en.png"),
            zh: require("../assets/MobileImage3.png"),
          },
          current: '', // will be updated in created() method
        },
        {
          img: {
            En: require("../assets/MobileImage4_en.png"),
            zh: require("../assets/MobileImage4.png"),
          },
          current: '', // will be updated in created() method
        },
      ],
      a3:require("@/assets/goBg.png"),
    };
  },
  created() {
    for (const item of this.img_arr) {
      item.current = item.img[this.lang]
    }
  },
  computed: {

  },
  mounted() {
    const scrollable=document.getElementById('scrollable')
    scrollable.scrollLeft=100
    // scrollable.addEventListener('scroll',()=>{
    //

    // })
  },
  methods:{
    switchHome1(index){
      this.home1Index=index
    },
    freeStoreOpen(){
      window.open('https://selleradmin.whatsbuy.com/#/login?register=true')
    },
  },
};
</script>

<style scoped lang="less">
.block{
  background: #000000;
}
.content{
  background-size: 100% 100%;
  height: 632px;
  .list{
    padding: 35px 0 0 21px;
    .li{
      height: 42px;
      background: #F2F2F2;
      border-radius: 5px 5px 5px 5px;
      margin-right: 7px;
      text-align: center;
      line-height: 42px;
      font-weight: 600;
      font-size: 15px;
      color: #202020;
      padding: 0 24px;
    }
    .act{
      border-bottom: 4px solid #9A40F4;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      color: #9A40F4;
    }
  }
  .homeInfo{
    margin-top: 38px;
    padding: 0 20px;
    img{
      width: 337px;
      height: 269px;
      background: #D9D9D9;
      border-radius: 10px 10px 10px 10px;
      margin-top: 44px;
    }
    .contentH8{
      font-weight: 600;
      font-size: 19px;
      color: #202020;
      margin-bottom: 15px;
    }
    .contentTxt{
      font-weight: 400;
      font-size: 15px;
      color: #000000;
    }
    .freeStoreOpen{
      height: 38px;
      background: #9A40F4;
      border-radius: 6px 6px 6px 6px;
      text-align: center;
      padding: 0 20px;
      font-weight: 600;
      font-size: 15px;
      color: #FFFFFF;
      line-height: 38px;
      margin-top: 16px;
    }


  }
}
.box2{
  .contentH8{
    font-weight: 600;
    font-size: 25px;
    color: #202020;
    padding: 64px 0 0;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    color: #202020;
    margin-bottom: 30px;
  }
  .homeInfo{
    padding: 0 20px;
    //align-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    .li{
      margin-bottom: 17px;
      img{
        width: 52px;
        height: 52px;
        margin-bottom:7px ;
      }
      .home2Info{
        .txt{
          font-weight: 600;
          font-size: 19px;
          color: #202020;
          width: 160px;
          margin-bottom: 9px;
        }
        .homeTxt{
          font-weight: 400;
          font-size: 15px;
          color: #000000;
          width: 160px;
        }
      }
    }
  }
}
.box3{
  padding: 31px 0 0;
  background: #eeeeef;
  .title{
    font-weight: 600;
    font-size: 31px;
    color: #000000;
    text-align: center;
    margin-bottom: 30px;
  }
  .scroll-view {
    overflow-x: auto;
    white-space: nowrap;
    background: #fff;
  }

  .scrollable-content {
    display: inline-block;
  }
  .topOne{
    margin-bottom: 17px;
    img{
      width: 130px;
      height: 42px;
      background: #FFFFFF;
      box-shadow: 13px -13 25px 0px rgba(255,255,255,0.3), -13px 13 25px 0px rgba(217,217,217,0.5);

    }
  }
  .topTwo{
    margin-bottom: 17px;
    img{
      width: 130px;
      height: 42px;
      background: #FFFFFF;
      box-shadow: 13px -13 25px 0px rgba(255,255,255,0.3), -13px 13 25px 0px rgba(217,217,217,0.5);

    }

  }
  .topThree{
    margin-bottom: 17px;
    img{
      width: 130px;
      height: 42px;
      background: #FFFFFF;
      box-shadow: 13px -13 25px 0px rgba(255,255,255,0.3), -13px 13 25px 0px rgba(217,217,217,0.5);

    }
  }
  .topFive{
    margin-bottom: 40px;
    img{
      width: 130px;
      height: 42px;
      background: #FFFFFF;
      box-shadow: 13px -13 25px 0px rgba(255,255,255,0.3), -13px 13 25px 0px rgba(217,217,217,0.5);

    }
  }
}
.box4{
  //width: 375px;
  height: 293px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //background: linear-gradient( 90deg, #9A40F4 0%, #33A8FF 100%);
  .label{
    font-weight: 700;
    font-size: 25px;
    color: #FFFFFF;
   padding-top: 58px;
    width: 307px;
    text-align: center;
    margin-left: 30px;
  }
  .freeStoreOpen{
    height: 46px;
    background: #FFFFFF;
    box-shadow: 0px 2 8px 0px rgba(0,60,102,0.4);
    border-radius: 4px 4px 4px 4px;
    padding: 0 20px;
    font-weight: 600;
    font-size: 19px;
    color: #9A40F4;
    line-height: 46px;
    margin-top: 43px;
  }
}
</style>
