<template>
  <div class="">
    <div class="view" v-if="!isMobile">
    <Tab @switchNavigation="switchNavigation" :actMenu="actMenu" @switchLanguage="switchLanguage"></Tab>
      <home v-if="actMenu==0" :lang="lang"></home>
      <functionalAdvantages v-if="actMenu==1" :lang="lang"></functionalAdvantages>
      <vendingMachine v-if="actMenu==2" :lang="lang"></vendingMachine>
      <helpCenter v-if="actMenu==3" :lang="lang"></helpCenter>
      <aboutUs v-if="actMenu==4" :lang="lang"></aboutUs>
      <footBox @switchNavigation="switchNavigation"></footBox>
    </div>
    <div class="view" v-if="isMobile">
      <MobileTab @switchNavigation="switchNavigation" :actMenu="actMenu" @switchLanguage="switchLanguage"></MobileTab>
      <MobileHome v-if="actMenu==0" :lang="lang"></MobileHome>
      <MobileFunctionalAdvantages v-if="actMenu==1" :lang="lang"></MobileFunctionalAdvantages>
      <MobileVendingMachine v-if="actMenu==2" :lang="lang"></MobileVendingMachine>
      <MobileHelpCenter v-if="actMenu==3" :lang="lang"></MobileHelpCenter>
      <MobileAboutUs v-if="actMenu==4" :lang="lang"></MobileAboutUs>
      <MobileFootBox @switchNavigation="switchNavigation"></MobileFootBox>
    </div>

  </div>
</template>
<script>
import Tab from "../components/my_tab.vue";
import footBox from "../components/footBox.vue";
import home from "../components/home.vue"
import  functionalAdvantages from "../components/functionalAdvantages.vue"
import  vendingMachine from "../components/vendingMachine.vue"
import  aboutUs from "../components/aboutUs.vue"
import  helpCenter from "../components/helpCenter.vue"

import  MobileTab from "../components/MobileTab.vue"
import  MobileHome from "../components/MobileHome.vue"
import  MobileFootBox from "../components/MobileFootBox.vue"
import  MobileFunctionalAdvantages from "../components/MobileFunctionalAdvantages.vue"
import  MobileAboutUs from "../components/MobileAboutUs.vue"
import  MobileVendingMachine from "../components/MobileVendingMachine.vue"
import  MobileHelpCenter from "../components/MobileHelpCenter.vue"

export default {
  data() {
    return {
      actMenu:0,
      home1Index:0,
      lang:'zh',
      windowWidth: 0,
    };
  },
  components: {
    Tab,
    footBox,
    home,
    functionalAdvantages,
    vendingMachine,
    aboutUs,
    helpCenter,
    MobileTab,
    MobileHome,
    MobileFootBox,
    MobileFunctionalAdvantages,
    MobileAboutUs,
    MobileVendingMachine,
    MobileHelpCenter
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if(localStorage.getItem('actMenu')){
      this.actMenu=localStorage.getItem('actMenu')
    }
    // this.changeLanguage(this.$i18n.locale);

  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    // localStorage.setItem('actMenu',0)
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 600;
    },
  },

  methods: {
    switchNavigation(index){
      localStorage.setItem('actMenu',index)
      window.scrollTo(0, 0)
      this.actMenu=index
    },
    switchLanguage(lang){
      this.lang=lang
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },

  },
};
</script>

<style lang="less" scoped>
.view {
  max-width: 1920px;
  margin: 0 auto;

  > div {
    img {
      width: 100%;
    }
  }

}



</style>
