<template>
  <div class="">
    <div class="block">
      <el-carousel height="240px">
        <el-carousel-item v-for="(item, index) in img_arr" :key="index">
          <div class="item">
            <img :src="item.current" style="width: 100%;height: 240px" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="box1">
        <div class="list row-a">
          <div :class="['li',type==1? 'act':'']" @click="switchType(1)">{{ $t('message.商家') }}</div>
          <div :class="['li',type==2? 'act':'']" @click="switchType(2)">{{ $t('message.供应商') }}</div>
          <div :class="['li',type==3? 'act':'']" @click="switchType(3)">{{ $t('message.售货机') }}</div>
        </div>
      </div>
        <div class="block1">
            <div class="box">
              <div class="label">
                {{type==1?$t('message.商家'):type==2? $t('message.供应商'):$t('message.售货机')}}
              </div>
<!--              <div class="list">-->
<!--                <div class="li row-b" v-for="(item,index) in menu" :key="index">-->
<!--                  {{ item.title }} <i class="el-icon-arrow-right" v-if="item.homePageTitleDTOList.length>0"></i>-->
<!--                </div>-->
<!--              </div>-->
              <el-menu
                  class="el-menu-vertical-demo"
                  @select="select"
                  background-color="#D9D9D9"
                  text-color="#030303"
                  active-text-color="#9A40F4">
                <div v-for="(item,index) in menu" :key="index">
                  <el-submenu :index="item.id" v-if="item.homePageTitleDTOList.length>0">
                    <template slot="title">
                      <span>{{ item.title }}</span>
                    </template>
                    <el-menu-item-group v-for="(it,idx) in item.homePageTitleDTOList" :key="idx">
                      <el-menu-item :index="it.id">{{ it.title }}</el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-menu-item :index="item.id" v-if="item.homePageTitleDTOList.length==0">
                    <span slot="title">{{ item.title }}</span>
                  </el-menu-item>
                </div>
              </el-menu>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {listHelpPage,getContent4Id} from '@/API/helpCenter'
export default {
  name: "Tab",
  data() {
    return {
      type:1,
      menu:[],
      content:'',
      defaultMenu:0,
      img_arr:[
        {
          img: {
            En: require("../assets/MobileImage6_en.png"),
            zh: require("../assets/MobileImage6.png"),
          },
          current: '', // will be updated in created() method
        },
      ],
    };
  },
  props:{
    lang: {
      type:String,
      default:'zh'
    }
  },
  watch:{
    lang(newValue){
      for (const item of this.img_arr) {
        item.current = item.img[newValue]
      }
    }
  },
  computed: {

  },
  created(){
    for (const item of this.img_arr) {
      item.current = item.img[this.lang]
    }
    this.listHelpPage()
  },
  methods:{
    switchType(index){
      this.type=index
      this.content=''
      this.listHelpPage()
    },
    async listHelpPage(){
      let res = await listHelpPage({type:this.type})
      if(res.data.length>0){
        this.menu=res.data
        // if(res.data[0].homePageTitleDTOList.length>0){
        //   this.defaultMenu=res.data[0].homePageTitleDTOList[0].id*1
        // }else {
        //   this.defaultMenu=res.data[0].id*1
        // }
        // this.getContent(this.defaultMenu)
      }else {
        this.menu=[]
      }


    },
    handleOpen(key, keyPath) {

    },
    handleClose(key, keyPath) {

    },
    select(key, keyPath) {

      let title=''
      if(keyPath.length==1){
        title=this.menu.filter(el=>{return el.id==key})[0].title
      }else {
        title=this.menu.filter(el=>{return el.id==keyPath[0]})[0].homePageTitleDTOList.filter(el=>{
          return el.id==keyPath[1]
        })[0].title
      }
      this.$router.push({
        path: '/details',
        query: {id:key,title:title
        }
      })


      // this.getContent(key)
    },
    async getContent(id){
      let res =await  getContent4Id({id:id})
      this.content=res.data

    },
  },
};
</script>

<style scoped lang="less">
.box1{
  .list{
    margin-bottom: 22px;
    padding: 0 20px;
    .li{
      //width:287px;
      height: 42px;
      font-weight: 400;
      font-size: 15px;
      color: #000000;
      border-bottom: 1px solid rgba(0,0,0,0.25);
      text-align: center;
      cursor:pointer;
      background: #F2F2F2;
      line-height: 42px;
      border-radius: 5px;
      padding: 0 10px;
      min-width: 70px;
    }
    .act{
      //font-weight: 900;
      color: #9A40F4;
      border-bottom: 3px solid #9A40F4;
    }
  }
}
.content{
  padding: 27px 0;
.block1{
  //margin: 0 170px;
  align-items: flex-start;

  .box{
    //width: 444px;
    border-radius: 13px 13px 13px 13px;
    //margin-right: 104px;
    .label{
      height: 65px;
      background: #9A40F4;
      text-align: center;
      line-height: 65px;
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 600;
      border-radius: 13px 13px 0px 0px;
    }
    .list{
      background: #d9d9d9;
      border-radius: 0px 0px 13px 13px;
      .li{
        padding: 20px 32px;
        font-weight: 500;
        font-size: 19px;
        color: #030303;
        cursor:pointer;
      }
      .li:last-child{
        padding-bottom:50px ;
      }
      .act{
        color: #9A40F4;
      }
    }
  }
}

}
/deep/.el-submenu__title{
  font-weight: 500;
  font-size: 16px;
  height: 60px;
  line-height: 60px;
}
/deep/.el-menu-item{
  height: 60px;
  line-height: 60px;
  font-weight: 500;
  font-size: 16px;
}
/deep/ .el-submenu__title i{
  font-size: 16px;
}
/deep/ .el-submenu .el-menu-item{
  height: 60px;
  line-height: 60px;
}
</style>
