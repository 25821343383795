// i18n.js
export const messages = {
    en: {
      message: {
          '首页':'Home',
          '功能优势':'Advantages',
          '小型售货机':'Small vending machine',
          '帮助中心':'Help Center',
          '关于我们':'About Us',
          '登录':'Log in',
          '免费开店':'Open a store for free',
          '什么人可以来':'Who can come',
          '新手建站':'Website building for beginners',
          '传统平台卖家':'Traditional platform sellers',
          '小额批发商':'Small wholesaler',
          '跨境SOHO':'Cross-border SOHO',
          '酒店/餐饮行业赋能人财':'Hotel/catering industry empowered personnel',
          '我是个新手':'I am a newbie and have never had any experience in opening an online store. I need one-to-one real-time online guidance, which can accompany me step by step from product selection, website layout, operation and promotion to order placement.',
          '我是传统卖家':'I am a traditional seller. My offline development has encountered a bottleneck and I have no customers. I want to expand my income channels by opening an online store.',
          '我是小额批发商':'I am a small wholesaler, and my business relies on traditional stores to purchase and distribute goods. Business is unstable, order tracking is irregular, and efficiency is low. I hope to change this model.',
          '我是一名老站长':'I am an old webmaster and have used many mall systems. Now I am always worried about the maintenance of the website. I hope to free myself so that I can concentrate on promotion.',
          '我是个酒店老板':'I am a hotel owner. I want to set up new retail activities in the hotel. I need a platform and vending machine to provide one-stop service for me.',
          '我们能帮助您解决什么问题':'What problems can we help you solve',
          '链接全球客户':'Link global customers',
          '使用营销工具':'Sell ​​your products using marketing tools, social integration and online shopping carts to bring your products to customers and sell them globally.',
          '打造您的品牌':'Build your brand',
          '创建自己的品牌名字':'Create your own brand name, your own brand trademark, and achieve your own brand.',
          '丰富营销手段':'Rich marketing tools',
          '单一营销手段':'Single marketing method, high customer acquisition cost',
          '酒店/餐饮赋能':'Hotel/catering empowerment',
          '上门送货':'Door-to-door delivery, new retail in hotels, small unmanned vending machines',
          '收款困难':'Difficulty collecting payment',
          '跨境资金收款困难':'Difficulty in collecting cross-border funds and unable to recover funds',
          '开发成本过高':'Development costs are too high',
          '单独开发自有商城难度系数大':'It is difficult to develop your own mall alone, with high IT costs and huge manpower and material resources.',
          '合作伙伴':'Partners',
          '现在加入我们':'Join us now and go overseas to make money with 200,000 sellers.',
          '出海首选建站平台':'The preferred website building platform when going overseas',
          '快速搭建跨境独立站':'Quickly build a cross-border independent website',
          'H5+移动端':'H5+ mobile terminal can adapt to a wider range of scenarios and operate more efficiently',
          '多行业独立站精美模板免费套用':'Free application of exquisite templates for multi-industry independent websites, you can quickly create your own store even without decoration experience',
          '网站自动适配移动端和web端':'The website automatically adapts to mobile and web',
          '支持本地多种货币':'Support multiple local currencies and multiple languages',
          '微信支付宝':'WeChat, Alipay, BCEL, KBZ pay, ACLEDA pay, Offline payment',
          '银行卡支付':'Bank card payment supports a variety of professional channels (Indonesian rupiah, riel, Philippine peso, Thai baht, Vietnamese dong)',
          '10种语言':'10 languages: Chinese, English, Lao, Khmer, Malaysian, Thai, Vietnamese, Burmese, Amharic, Indonesian',
          '丰富的营销玩法':'Rich marketing methods',
          '优惠券':'Coupons, limited-time offers, discounts/gifts with purchases, group bookings',
          '一键分享微信':'One-click sharing on WeChat, Facebook, X, WhatsApp',
          '更好的消费与分享':'Better consumption and sharing',
          '消费得积分':'Earn points for consumption and exchange them for gifts to stimulate consumption',
          '搭建二级分销体系':'Build a secondary distribution system where old customers share products and receive rewards',
          '酒店餐饮赋能':'Hotel and catering empowerment',
          '酒店新零售':'New retail in hotels, comprehensive access to personalized vending machines, increasing hotel revenue',
          '自助扫码点餐':'Self-service scanning code ordering saves time and effort and improves efficiency',
          '专业团队':'Professional team, safe and reliable',
          '强大的研发实力':'Strong R&D strength, safe, stable and reliable system support',
          '团队深耕东南亚市场多年':'The team has been deeply involved in the Southeast Asian market for many years and has rich industry experience. It pursues online stores that are more suitable for Southeast Asian usage habits.',
          '产品中心':'Product Center',
          '产品特点':'Features',
          '小型酒店售货机是一种基于互联网和智能技术的自动售货机':'The small hotel vending machine is a vending machine based on the Internet and smart technology, with a variety of functions and features.',
          '可帮助酒店提升旅客体验':'It can help hotels improve passenger experience. Customers can quickly purchase condoms, family planning supplies or adult sex toys by scanning the QR code on their mobile phones to avoid embarrassment and protect privacy. Hotel unmanned vending machines make hotel operations more convenient, provide 24-hour uninterrupted self-service shopping services, and can achieve steady growth in revenue without adding additional manpower. They are truly new retail smart devices.',
          '立式橱窗':'Vertical display window, translucent design',
          '电池/电源供电':'Battery/power supply, safe operation for a long time',
          '蓝牙连接':'Bluetooth connection, stable signal',
          '多种配色可选':'A variety of colors are available to suit different hotel styles',
          '缺货提醒':'Out of stock reminder, one-click replenishment',
          '手机扫码支付':'Scan the QR code with your mobile phone to pay. Scan the QR code to pick up the goods conveniently without the need to check out at the front desk to protect your privacy.',
          '我们的优势':'Our advantages',
          '服务周到':'Good service',
          '一站式服务':'One-stop service, hardware + software + product supply + operation training',
          '性能优秀':'Excellent performance',
          '设备性能稳定便捷':'Equipment performance is stable and convenient',
          '价格更低':'Lower price',
          '量大价格低':'Large quantity, low price, win-win model brings additional income',
          '互利分成':'Mutually beneficial sharing',
          '酒店合作联营':'Hotel cooperation and joint venture, mutual benefit and win-win',
          '合作流程':'Cooperation process',
          '在线咨询':'Online consultation',
          '了解需求':'Understand the needs',
          '制定方案':'Make a plan',
          '签订合同':'Sign a contract',
          '投入使用':'Put into use',
          '售后维护':'After-sales maintenance',
          '5格售卖机':'5 grid vending machine',
          '6格售卖机':'6 grid vending machine',
          '7格售卖机':'7 grid vending machine',
          '8格售卖机':'8 grid vending machine',
          '关于WhatsBuy':'About WhatsBuy',
          'WhatsBuy为长沙好滴科技有限公司所有':'WhatsBuy is owned by HONG KONG WB TECHNOLOGY LIMITED, Ltd. It focuses on cross-border e-commerce brands entering the global market and is committed to building a global cross-border e-commerce full-link ecological platform. It has now served tens of thousands of sellers and tens of millions of consumers around the world. ',
          '哇呗':'WhatsBuy, as an e-commerce website building tool serving global merchants. Brand owners and suppliers establish cooperative relationships with many distributors and merchants through Wabei to achieve massive distribution and boost sales. The platform empowers sellers to easily open stores, radiates global users, and provides comprehensive insights through smart data dashboards to enable efficient operations.',
          '联系我们':'Contact us',
          '地址':'Address',
          '湖南省长沙市岳麓区街道中建智慧产业园':'HONG KONG CENTRE, NO.45, HOI YUEN ROAD,UNIT 89, 3/F., YAU LEE',
          '邮箱':'E-Mail',
          '联系电话':'Contact number',
          '周一至周五':'Monday to Friday',
          '行业解决方案':'Industry solutions',
          '行业痛点':'Industry pain points',
          '客户案例':'Customer case',
          '成为合作伙伴':'Become a partner',
          '独立建站':'Independent website building',
          '酒店售货机':'Hotel vending machine',
          '使用指南':'User Guide',
          '常见问题':'FAQ',
          '合作':'Coperate',
          '公司简介':'Company Profile',
          '关注我们':'Follow us',
          '微信公众号':'WeChat public account',
          '微信客服':'WeChat customer service',
          '友情链接':'Friendly links',
          '惜时网络':'Xishi Network',
          '八万里':'Bawanli',
          '长沙好滴科技有限公司©版权所有':'HONG KONG WB TECHNOLOGY LIMITED,Ltd. © All Rights Reserved',
          '商家':'Merchant',
          '供应商':'Supplier',
          '售货机':'Vending machine',
          '馈喜信息':'Kuixi',
          '境外生活通':'Oversease life tong '
      }
    },
    zh: {
      message: {
          '馈喜信息':'馈喜信息',
          '境外生活通':'境外生活通',
          '首页':'首页',
          '功能优势':'功能优势',
          '小型售货机':'小型售货机',
          '帮助中心':'帮助中心',
          '关于我们':'关于我们',
          '登录':'登录',
          '免费开店':'免费开店',
          '什么人可以来':'什么人可以来',
          '新手建站':'新手建站',
          '传统平台卖家':'传统平台卖家',
          '小额批发商':'小额批发商',
          '跨境SOHO':'跨境SOHO',
          '酒店/餐饮行业赋能人财':'酒店/餐饮行业赋能人员',
          '我是个新手':'我是个新手，从未有过线上开店经验。我需要一对一实时在线指导，从选品，网站布置，运营推广等一步一步陪我到出单。',
          '我是传统卖家':'我是传统卖家，在线下发展已经遇到了瓶颈，没有客户沉淀，我想通过线上开店来扩展收入渠道。',
          '我是小额批发商':'我是小额批发商，生意靠传统店铺进货、铺货。生意不稳定，订单跟踪不规范，效率低下，我希望改变这个模式。',
          '我是一名老站长':'我是一名老站长，用过许多商城系统，现在总在为网站的维护烦恼，我希望能释放自己，让我专心投入推广。',
          '我是个酒店老板':'我是个酒店老板，我希望在酒店设置新的零售活动，我需要平台和售货机，一站式替我服务',
          '我们能帮助您解决什么问题':'我们能帮助您解决什么问题',
          '链接全球客户':'链接全球客户',
          '使用营销工具':'使用营销工具、社交整合和在线购物车销售您的产品，将您的产品推向顾客，卖向全球。',
          '打造您的品牌':'打造您的品牌',
          '创建自己的品牌名字':'创建自己的品牌名字，自己的品牌商标，成就自己的品牌。',
          '丰富营销手段':'丰富营销手段',
          '单一营销手段':'单一营销手段，拓客成本高',
          '酒店/餐饮赋能':'酒店/餐饮赋能',
          '上门送货':'上门送货、酒店新零售，小型无人售货机',
          '收款困难':'收款困难',
          '跨境资金收款困难':'跨境资金收款困难，无法回款',
          '开发成本过高':'开发成本过高',
          '单独开发自有商城难度系数大':'单独开发自有商城难度系数大，IT成本高、人力物力耗资巨大。',
          '合作伙伴':'合作伙伴',
          '现在加入我们':'现在加入我们，和20万卖家一起出海掘金。',
          '出海首选建站平台':'出海首选建站平台',
          '快速搭建跨境独立站':'快速搭建跨境独立站',
          'H5+移动端':'H5+移动端，适应场景更广泛，经营更高效',
          '多行业独立站精美模板免费套用':'多行业独立站精美模板免费套用，无装修经验也能快速打造专属店铺',
          '网站自动适配移动端和web端':'网站自动适配移动端和web端',
          '支持本地多种货币':'支持本地多种货币、多种语言',
          '微信支付宝':'微信、支付宝、BCEL、KBZ pay、ACLEDA pay、线下支付',
          '银行卡支付':'银行卡支付，支持丰富的专业通道（印尼卢比、瑞尔、菲律宾比索、泰铢、越南盾）',
          '10种语言':'10种语言：中文、英语、老挝语、高棉语、马来西亚语、泰语、越南语、缅甸语、阿姆哈拉语、印尼语',
          '丰富的营销玩法':'丰富的营销玩法',
          '优惠券':'优惠券、限时优惠、满减/满赠、拼团',
          '一键分享微信':'一键分享微信、Facebook、X、WhatsApp',
          '更好的消费与分享':'更好的消费与分享',
          '消费得积分':'消费得积分，换礼品，刺激消费',
          '搭建二级分销体系':'搭建二级分销体系，老顾客分享商品，获得奖励',
          '酒店餐饮赋能':'酒店餐饮赋能',
          '酒店新零售':'酒店新零售，全面打通接入个性售货机，增加酒店收入',
          '自助扫码点餐':'自助扫码点餐，省时省力，提高效率',
          '专业团队':'专业团队，安全可靠',
          '强大的研发实力':'强大的研发实力，安全稳定可靠的系统支持',
          '团队深耕东南亚市场多年':'团队深耕东南亚市场多年，有着丰富的行业经验，追求更适合东南亚使用习惯的在线店铺',
          '产品中心':'产品中心',
          '产品特点':'产品特点',
          '小型酒店售货机是一种基于互联网和智能技术的自动售货机':'小型酒店售货机是一种基于互联网和智能技术的自动售货机，具有多种功能和特点。',
          '可帮助酒店提升旅客体验':'可帮助酒店提升旅客体验，客户通过手机扫码支付，快速实现避孕套计生用品或成人情趣用品购买，避免尴尬保护隐私。酒店无人售货机让酒店经营更加便捷，提供24小时不间断自助购物服务，能够在不增加额外人力的前提下，实现收入的稳步增长，是真正的新零售智能设备。',
          '立式橱窗':'立式橱窗，半透明设计',
          '电池/电源供电':'电池/电源供电，长时间安全运',
          '蓝牙连接':'蓝牙连接，信号稳定',
          '多种配色可选':'多种配色可选，适配不同酒店风格',
          '缺货提醒':'缺货提醒，一键补货',
          '手机扫码支付':'手机扫码支付，扫码方便取货无需前台结账，保护隐私',
          '我们的优势':'我们的优势',
          '服务周到':'服务周到',
          '一站式服务':'一站式服务，硬件+软件+商品供应+运营培训',
          '性能优秀':'性能优秀',
          '设备性能稳定便捷':'设备性能稳定便捷',
          '价格更低':'价格更低',
          '量大价格低':'量大价格低，双赢模式 带来额外收益',
          '互利分成':'互利分成',
          '酒店合作联营':'酒店合作联营，互利互赢',
          '合作流程':'合作流程',
          '在线咨询':'在线咨询',
          '了解需求':'了解需求',
          '制定方案':'制定方案',
          '签订合同':'签订合同',
          '投入使用':'投入使用',
          '售后维护':'售后维护',
          '5格售卖机':'5格售卖机',
          '6格售卖机':'6格售卖机',
          '7格售卖机':'7格售卖机',
          '8格售卖机':'8格售卖机',
          '关于WhatsBuy':'关于WhatsBuy',
          'WhatsBuy为长沙好滴科技有限公司所有':'WhatsBuy为香港哇呗科技有限公司所有，专注于跨境电商品牌出海全球市场，致力于打造面向全球的跨境电商全链路生态平台，现已服务全球数万卖家和千万级消费者。',
          '哇呗':'哇呗，作为一款服务于全球商家的电商建站工具。品牌商与供应商通过哇呗与众 多分销商及商家建立合作关系，实现海量分销，助力销售。平台赋能卖家轻松开店，辐射全球用户，智能数据看板全面洞察，开启高效经营。',
          '联系我们':'联系我们',
          '地址':'地址',
          '湖南省长沙市岳麓区街道中建智慧产业园':'HONG KONG CENTRE, NO.45, HOI YUEN ROAD,UNIT 89, 3/F., YAU LEE',
          '邮箱':'邮箱',
          '联系电话':'联系电话',
          '周一至周五':'周一至周五',
          '行业解决方案':'行业解决方案',
          '行业痛点':'行业痛点',
          '客户案例':'客户案例',
          '成为合作伙伴':'成为合作伙伴',
          '独立建站':'独立建站',
          '酒店售货机':'酒店售货机',
          '使用指南':'使用指南',
          '常见问题':'常见问题',
          '合作':'合作',
          '公司简介':'公司简介',
          '关注我们':'关注我们',
          '微信公众号':'微信公众号',
          '微信客服':'微信客服',
          '友情链接':'友情链接',
          '惜时网络':'惜时网络',
          '八万里':'八万里',
          '长沙好滴科技有限公司©版权所有':'香港哇呗科技有限公司©版权所有',
          '商家':'商家',
          '供应商':'供应商',
          '售货机':'售货机'
      }
    }
  }
