import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueI18n from 'vue-i18n'
import { messages } from './lang'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(ElementUI);
Vue.use(VueI18n)
Vue.use(Vant);

const i18n = new VueI18n({
  locale: 'en', // 默认语言
  messages,
})


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
