<template>
  <div class="bigBox">
    <div class="block">
      <el-carousel height="493px">
        <el-carousel-item v-for="(item, index) in img_arr" :key="index">
          <div class="item">
            <img :src="item.current" style="width: 100%;height: 493px" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="block1">
        <div class="h5">{{ $t('message.关于WhatsBuy') }}</div>
        <div class="label">{{ $t('message.WhatsBuy为长沙好滴科技有限公司所有') }}</div>
        <div class="txt">{{ $t('message.哇呗') }}</div>
      </div>
    </div>
    <div class="home1" :style="{ backgroundImage: `url(${a1})`}">
      <div class="block1 row-c row-d">
          <div class="h5">{{ $t('message.联系我们') }}</div>
          <div class="list row-b">
            <div class="li row-s">
              <img src="@/assets/about1.png" />
              <div class="info">
                <div class="label">{{ $t('message.地址') }}：</div>
                <div class="txt">{{ $t('message.湖南省长沙市岳麓区街道中建智慧产业园') }}</div>
              </div>
            </div>
            <div class="li row-s">
              <img src="@/assets/about2.png" />
              <div class="info">
                <div class="label">{{ $t('message.邮箱') }}：</div>
                <div class="txt">xishiwhatsbuy@gmail.com</div>
              </div>
            </div>
            <div class="li row-s">
              <img src="@/assets/about3.png" />
              <div class="info">
                <div class="label">{{ $t('message.联系电话') }}：</div>
                <div class="txt">0731-8550-8601</div>
              </div>
            </div>
            <div class="li row-s">
              <img src="@/assets/about4.png" />
              <div class="info">
                <div class="label">{{ $t('message.周一至周五') }}：</div>
                <div class="txt">09:00-18:00</div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tab",
  data() {
    return {
      a1:require("@/assets/aboutUsBg.png"),
      img_arr:[
        {
          img: {
            En: require("../assets/image7_en.png"),
            zh: require("../assets/image7.png"),
          },
          current: '', // will be updated in created() method
        },
      ],
    };
  },
  props:{
    lang: {
      type:String,
      default:'zh'
    }
  },
  watch:{
    lang(newValue){
      for (const item of this.img_arr) {
        item.current = item.img[newValue]
      }
    }
  },
  created(){
    for (const item of this.img_arr) {
      item.current = item.img[this.lang]
    }
  },
  computed: {

  },
  methods:{

  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 960px)  and (min-width:1440px){
  .bigBox{
    zoom: 80%;
  }
  .content{
    //height: 611px;
    .block1{
      margin: 0 170px;
      .h5{
        padding: 143px 0 75px 0;
        font-weight: 600;
        font-size: 53px;
        color: #202020;
        text-align: center;

      }
      .label{
        font-weight: 400;
        font-size: 29px;
        color: #202020;
        line-height: 50px;
        text-indent: 2em;
      }
      .txt{
        font-weight: 400;
        font-size: 29px;
        color: #202020;
        line-height: 50px;
        text-indent: 2em;
      }

    }
  }
  .home1{
    //height: 564px;
    background-size: 100%;
    .block1{
      margin: 0 170px;
      .h5{
        font-weight: 600;
        font-size: 53px;
        color: #202020;
        text-align: center;
        padding: 88px 0 39px;
      }
      .list{
        flex-wrap: wrap;
        .li{
          margin-right: 200px;
          //width: 50%;
          //margin-right: 200px;
          align-items: flex-start;
          margin-bottom: 70px;
          img{
            width: 53px;
            height: 53px;
            margin-right: 27px;
          }
          .info{
            .label{
              font-weight: 700;
              font-size: 32px;
              color: #000000;
              margin-bottom: 10px;
            }
            .txt{
              width: 293px;
              font-weight: 400;
              font-size: 21px;
              color: #8F8F8F;
            }
          }
        }
        .li:nth-child(2n){
          //margin-left: 20px;
          margin-right: 0;
        }
      }
    }
  }
}
@media  screen and (min-width:1650px)and (max-width: 1657px){
  .bigBox{
    zoom: 80%;
  }
  .content{
    //height: 611px;
    .block1{
      margin: 0 170px;
      .h5{
        padding: 143px 0 75px 0;
        font-weight: 600;
        font-size: 53px;
        color: #202020;
        text-align: center;

      }
      .label{
        font-weight: 400;
        font-size: 29px;
        color: #202020;
        line-height: 50px;
        text-indent: 2em;
      }
      .txt{
        font-weight: 400;
        font-size: 29px;
        color: #202020;
        line-height: 50px;
        text-indent: 2em;
      }

    }
  }
  .home1{
    //height: 564px;
    background-size: 100%;
    .block1{
      margin: 0 550px;
      .h5{
        font-weight: 600;
        font-size: 53px;
        color: #202020;
        text-align: center;
        padding: 88px 0 39px;
      }
      .list{
        flex-wrap: wrap;
        .li{
          margin-right: 200px;
          //width: 50%;
          //margin-right: 200px;
          align-items: flex-start;
          margin-bottom: 70px;
          img{
            width: 53px;
            height: 53px;
            margin-right: 27px;
          }
          .info{
            .label{
              font-weight: 700;
              font-size: 32px;
              color: #000000;
              margin-bottom: 10px;
            }
            .txt{
              width: 293px;
              font-weight: 400;
              font-size: 21px;
              color: #8F8F8F;
            }
          }
        }
        .li:nth-child(2n){
          //margin-left: 20px;
          margin-right: 0;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1550px){
  .bigBox{
    zoom: 60%;
  }
  .content{
    //height: 611px;
    .block1{
      margin: 0 170px;
      .h5{
        padding: 143px 0 75px 0;
        font-weight: 600;
        font-size: 53px;
        color: #202020;
        text-align: center;

      }
      .label{
        font-weight: 400;
        font-size: 29px;
        color: #202020;
        line-height: 50px;
        text-indent: 2em;
      }
      .txt{
        font-weight: 400;
        font-size: 29px;
        color: #202020;
        line-height: 50px;
        text-indent: 2em;
      }

    }
  }
  .home1{
    //height: 564px;
    background-size: 100%;
    .block1{
      margin: 0 550px;
      .h5{
        font-weight: 600;
        font-size: 53px;
        color: #202020;
        text-align: center;
        padding: 88px 0 39px;
      }
      .list{
        flex-wrap: wrap;
        .li{
          margin-right: 200px;
          //width: 50%;
          //margin-right: 200px;
          align-items: flex-start;
          margin-bottom: 70px;
          img{
            width: 53px;
            height: 53px;
            margin-right: 27px;
          }
          .info{
            .label{
              font-weight: 700;
              font-size: 32px;
              color: #000000;
              margin-bottom: 10px;
            }
            .txt{
              width: 293px;
              font-weight: 400;
              font-size: 21px;
              color: #8F8F8F;
            }
          }
        }
        .li:nth-child(2n){
          //margin-left: 20px;
          margin-right: 0;
        }
      }
    }
  }
}
</style>
