<template>
    <div>
        <van-nav-bar :title="title" fixed placeholder :border="borderBottom" z-index="2">
            <template #left>
                <van-icon name="arrow-left" size="18" :color="color" @click="goBack" />
            </template>
        </van-nav-bar>
    </div>
</template>
details
<script>
export default {
    name: 'goBack',
    props: {
        title: String,
        type: Number,
        border: {
            type: Boolean,
            default: true
        },
        color33: {
            type: String,
            default: '#333'
        },
        letc:Number,
      bluetooth:{
          type:Boolean,
          default:false
      }
    },
    data() {
        return {
            borderBottom: this.border,
            color: this.color33
        }
    },

    methods: {
        goBack() {
            this.$router.go(-1)
            this.$emit('goBack')
        },
    }
}
</script>

<style scoped></style>
