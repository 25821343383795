<template>
  <div class="">
    <div class="title row-b">
      <img src="../assets/logo2.png" alt="" />
      <div class="row-s">
        <el-dropdown :trigger="'click'" @command="switchLanguage">
            <span class="el-dropdown-link" style="color: #fff">
              {{lang == 'zh'?'中文(简体)':'English'}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="lang == 'zh'?'En':'zh'" style="font-size: 19px">{{ lang == 'zh'?'English':'中文(简体)' }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <i style="margin-left: 10px" class="el-icon-s-operation" @click="show=true"></i>
      </div>
    </div>
    <transition name="fade">
    <div class="leftMenu" v-if="show" >
      <div class="row-b label">
        <img src="../assets/logo.png" alt="" />
        <i class="el-icon-close" @click="show=false"></i>
      </div>
      <div :class="['txt',actMenu==0?'act':'']" @click="switchNavigation(0)">
        {{ $t('message.首页') }}
      </div>
      <div :class="['txt',actMenu==1?'act':'']" @click="switchNavigation(1)">
        {{ $t('message.功能优势') }}
      </div>
      <div :class="['txt',actMenu==2?'act':'']" @click="switchNavigation(2)">
        {{ $t('message.小型售货机') }}
      </div>
      <div :class="['txt',actMenu==3?'act':'']" @click="switchNavigation(3)">
        {{ $t('message.帮助中心') }}
      </div>
      <div :class="['txt',actMenu==4?'act':'']" @click="switchNavigation(4)">
        {{ $t('message.关于我们') }}
      </div>
      <div :class="['txt',actMenu==5?'act':'']" @click="freeStoreOpen">
        {{ $t('message.免费开店') }}
      </div>
    </div>
    </transition>
    <div class="block" v-if="show" @click="show=false" ></div>
    <div style="height: 47px"></div>
  </div>
</template>

<script>

export default {
  name: "MobileTab",
  props:{
    actMenu: {
      type:Number,
      default:0
    }
  },
  data() {
    return {
      lang:'zh',
      show:false
    };
  },
  computed: {

  },
  created() {
    if(localStorage.getItem('lang')=='en_US'){
      this.$i18n.locale='En'
    }else {
      this.$i18n.locale='zh'
    }
    this.switchLanguage(this.$i18n.locale);

  },
  methods:{
    goShop(){
      window.open('https://selleradmin.whatsbuy.com/')
    },
    freeStoreOpen(){
      window.open('https://selleradmin.whatsbuy.com/#/login?register=true')
    },
    switchLanguage(lang){
      this.lang=lang
      this.$i18n.locale = lang;
      if(lang=='En'){
        localStorage.setItem('lang','en_US')
      }else {
        localStorage.setItem('lang','zh_CN')
      }
      this.$emit('switchLanguage',lang)
    },
    switchNavigation(index){
      this.show=false
      // this.actMenu=index
      this.$emit('switchNavigation',index)
    },
  },
};
</script>

<style scoped lang="less">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.title {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 14px;
  z-index: 2;
  background: #222222;
  height: 47px;
  font-size: 24px;
  color: #fff;
  img{
    width: 103px;
    height: 21px;

  }
}
.leftMenu{
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  background: #fff;
  z-index: 3;
  .label{
    padding: 20px;
    font-size: 24px;
    color: #000;
    img{
      width: 103px;
      height: 21px;
    }
  }
  .txt{
    padding: 20px;
    font-size: 16px;
    color: #000;
  }
  .act{
    color: #9A40F4;
  }
}
.block{
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #939393;
  opacity: .4;
  z-index: 0;
}
</style>
