<template>
  <div class="bigBox">
    <div class="title row-b">
      <div class=" row-s">
        <img src="../assets/logo.png" alt="" />
        <div class="navMenu row-s">
          <div :class="['label',actMenu==0?'act':'']" @click="switchNavigation(0)">

            {{ $t('message.首页') }}
          </div>
          <div :class="['label',actMenu==1?'act':'']" @click="switchNavigation(1)">

            {{ $t('message.功能优势') }}
          </div>
          <div :class="['label',actMenu==2?'act':'']" @click="switchNavigation(2)">

            {{ $t('message.小型售货机') }}
          </div>
          <div :class="['label',actMenu==3?'act':'']" @click="switchNavigation(3)">

            {{ $t('message.帮助中心') }}
          </div>
          <div :class="['label',actMenu==4?'act':'']" @click="switchNavigation(4)">

            {{ $t('message.关于我们') }}
          </div>
        </div>
      </div>
      <div class="row-s">
        <el-dropdown :trigger="'click'" @command="switchLanguage">
            <span class="el-dropdown-link">
              {{lang == 'zh'?'中文(简体)':'English'}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="lang == 'zh'?'En':'zh'" style="font-size: 19px">{{ lang == 'zh'?'English':'中文(简体)' }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="signIn" @click="goShop">
          {{ $t('message.登录') }}
        </div>
        <div class="freeStoreOpen" @click="freeStoreOpen">
          {{ $t('message.免费开店') }}
        </div>
      </div>
    </div>
    <div style="height: 80px"></div>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props:{
    actMenu: {
      type:Number,
      default:0
    }
  },
  data() {
    return {
      lang:'zh'
    };
  },
  computed: {

  },
  created() {
    if(localStorage.getItem('lang')=='en_US'){
      this.$i18n.locale='En'
    }else {
      this.$i18n.locale='zh'
    }
    this.switchLanguage(this.$i18n.locale);

  },
  methods:{
    goShop(){
      window.open('https://selleradmin.whatsbuy.com/')
    },
    freeStoreOpen(){
      window.open('https://selleradmin.whatsbuy.com/#/login?register=true')
    },
    switchLanguage(lang){
      this.lang=lang
      this.$i18n.locale = lang;
      if(lang=='En'){
        localStorage.setItem('lang','en_US')
      }else {
        localStorage.setItem('lang','zh_CN')
      }
      this.$emit('switchLanguage',lang)

    },
    switchNavigation(index){
      // this.actMenu=index
      this.$emit('switchNavigation',index)
    },
  },
};
</script>

<style scoped lang="less">
.bigBox{
  zoom: 80%;
}
.title {
  height: 80px;
  padding: 0 40px 0 120px;
  color: #fff;
  font-size: 26px;
  line-height: 85px;
  background-color: #222222;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  img {
    display: inline-block;
    //margin-left: 80px;
    //margin-top: 15px;
    width: 197px;
    height: 40px;
  }
  .navMenu{
    margin-left: 73px;
    .label{
      font-size: 19px;
      color: #fff;
      margin-right: 65px;
      cursor:pointer;
    }
    .act{
      color: #9A40F4;
    }
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #fff;
    font-size: 19px;
  }
  .el-icon-arrow-down {
    font-size: 18px;
  }
  .signIn{
    width: 93px;
    height: 40px;
    background: #736A6A;
    border-radius: 8px 8px 8px 8px;
    margin-right: 16px;
    font-size: 19px;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    margin-left: 20px;
    cursor:pointer;
  }
  .freeStoreOpen{
    //width: 128px;
    padding: 0 20px;
    height: 40px;
    background: #9A40F4;
    box-shadow: 0px 3 11px 0px rgba(0,60,102,0.4);
    border-radius: 5px 5px 5px 5px;
    font-size: 19px;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    cursor:pointer;
  }

}
</style>
